import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = envManager.getPrefix() + 'mailResponse'

/**
 * get fireBaseDocs and return usable data
 * @param {fireBaseDocs} response 
 * @returns usable data
 */
const toValy = response => {
    const lists = []
    response.forEach(document => {
        const data = document.data()
        lists.push(new MailResponse(
            document.id,
            data.mail,
            data.date,
            data.sender,
            data.message,
            data.attachments,
            data.form,
            data.formLastUpdate
        ))
    })
    return lists
}
const oneToValy = response => {
    const data = response.data()
    return new MailResponse(
        response.id, data.mail,
        data.date, data.sender,
        data.message,
        data.attachments,
        data.form,
        data.formLastUpdate
    )
}

const keepOnlyOneResponse = response => {
    let kept = null
    let latest = 0

    response.forEach(document => {
        let mailResponse = oneToValy(document)
        let lastUpdate = mailResponse.formLastUpdate !== null ? mailResponse.formLastUpdate : mailResponse.date

        // if new is after old
        if (lastUpdate > latest) {
            // delete previous kept
            if (kept !== null) {
                kept.delete()
            }

            // keep new response
            kept = mailResponse
            latest = mailResponse.formLastUpdate
        } else {
            mailResponse.delete()
        }
    })

    return kept
}

class MailResponse{
    constructor(id, mail, date, sender, message, attachments = [], form = null, formLastUpdate = null){
        this.id = id
        this.mail = mail
        this.date = date
        this.sender = sender
        this.message = message
        this.attachments = attachments
        this.form = form
        this.formLastUpdate = formLastUpdate
    }

    // get
    static async getAll(){
        const collectionRef = collection(db, collectionName)
        const response = await getDocs(collectionRef)

        return toValy(response)
    }
    static async getByMail(mailId){
        const collectionRef = collection(db, collectionName)
        const documentsQuery = query(collectionRef, where("mail", "==", mailId))
        const response = await getDocs(documentsQuery)

        return toValy(response)
    }
    static async getBySender(senderId){
        const collectionRef = collection(db, collectionName)
        const documentsQuery = query(collectionRef, where("sender", "==", senderId))
        const response = await getDocs(documentsQuery)

        return toValy(response)
    }
    static async getByMailAndSender(mailId, senderId){
        const collectionRef = collection(db, collectionName)
        const documentsQuery = query(collectionRef, where("sender", "==", senderId), where("mail", "==", mailId))
        const response = await getDocs(documentsQuery)

        let mailResponse = keepOnlyOneResponse(response)
        
        return mailResponse
    }

    // listen
    static listenAll(callback = null){
        const collectionRef = collection(db, collectionName)

        const unsub = onSnapshot(collectionRef, snapshot => {
            const data = toValy(snapshot)
            if (callback != null) {
                callback(data)
            }
        })
        return unsub
    }
    static listenByMail(mailId, callback = null){
        const collectionRef = collection(db, collectionName)
        const documentsQuery = query(collectionRef, where("mail", "==", mailId))
    
        const unsub = onSnapshot(documentsQuery, snapshot => {
            const data = toValy(snapshot)
            if (callback != null) {
                callback(data)
            }
        })
        return unsub
    }
    static listenBySender(senderId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("sender", "==", senderId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            const data = toValy(snapshot)
            if(callback != null){
                callback(data)
            }
        })
        return unsub
    }
    static listenByMailAndSender(mailId,senderId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("sender", "==", senderId), where("mail", "==", mailId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let mailResponse = keepOnlyOneResponse(snapshot)
            
            if (callback != null) {
                callback(mailResponse)
            }
        })
        return unsub
    }

    
    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }
    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                mail : this.mail,
                date : this.date,
                sender : this.sender,
                message : this.message,
                attachments : this.attachments,
                form : this.form,
                formLastUpdate : this.formLastUpdate
            })
            this.id = response.id
        }else{
            let response = (await getDoc(doc(db, collectionName, this.id))).data()

            if (!response.formLastUpdate || response.formLastUpdate < this.formLastUpdate) {
                await updateDoc(doc(db, collectionName, this.id), {
                    mail : this.mail,
                    date : this.date,
                    sender : this.sender,
                    message : this.message,
                    attachments : this.attachments,
                    form : this.form,
                    formLastUpdate : this.formLastUpdate
                })
            }
        }
    }
    
    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default MailResponse