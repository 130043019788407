import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionPrefix = envManager.getPrefix()
let collectionId = "logs"
let collectionName = collectionPrefix + collectionId

class Log{
    constructor(id,profileId,type,message,timestamp = new Date().getTime()){
        this.id = id
        this.profileId = profileId
        this.type = type
        this.message = message
        this.timestamp = timestamp
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let logs = []

        response.forEach(document => {
            let data = document.data()
            let tmp_log = new Log(document.id, data.profileId, data.type, data.message, data.timestamp)
            logs.push(tmp_log)
        })

        return logs
    }
    
    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let log = new Log(response.id, data.profileId, data.type, data.message, data.timestamp)

        return log
    }
    
    static async getByProfileId(profileId){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("profileId", "==", profileId))
        let response = await getDocs(documentsQuery)

        let logs = []

        response.forEach(document => {
            let data = document.data()
            let tmp_log = new Log(document.id, data.profileId, data.type, data.message, data.timestamp)
            logs.push(tmp_log)
        })
        return logs
    }
    
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let logs = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_log = new Log(document.id, data.profileId, data.type, data.message, data.timestamp)
                logs.push(tmp_log)
            })
            
            if(callback != null){
                callback(logs)
            }
        })
        return unsub
    }
    
    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let log = new Log(snapshot.id, data.profileId, data.type, data.message, data.timestamp)
            
            if(callback != null){
                callback(log)
            }
        })
        return unsub
    }

    static listenByProfileId(profileId, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("profileId", "==", profileId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let logs = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_log = new Log(document.id, data.profileId, data.type, data.message, data.timestamp)
                logs.push(tmp_log)
            })
            
            if(callback != null){
                callback(logs)
            }
        })
        return unsub
    }
    
    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                profileId: this.profileId,
                type: this.type,
                message: this.message,
                timestamp: this.timestamp,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                profileId: this.profileId,
                type: this.type,
                message: this.message,
                timestamp: this.timestamp,
            })
        }
    }
    
    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }

    toReadableData(){
        return {
            profileId: this.profileId,
            type: this.type,
            message: this.message,
            timestamp: new Date(this.timestamp).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' }),
        }
    }
}

export default Log