import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionPrefix = envManager.getPrefix()
let collectionId = "files"
let collectionName = collectionPrefix + collectionId

class File{
    constructor(id, room, date, type, path, name, ref){
        this.id = id
        this.room = room
        this.date = date
        this.type = type
        this.path = path
        this.name = name
        this.ref = ref
    }

    // get
    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let files = []

        response.forEach(document => {
            let data = document.data()
            let tmp_file = new File(document.id, data.room, data.date, data.type, data.path, data.name, data.ref)
            files.push(tmp_file)
        })

        return files
    }
    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()

        return (!data) ? null : new File(response.id, data.room, data.date, data.type, data.path, data.name, data.ref)
    }
    static async getByRoom(roomId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
        let response = await getDocs(documentsQuery)

        let files = []

        response.forEach(document => {
            let data = document.data()
            let tmp_file = new File(document.id, data.room, data.date, data.type, data.path, data.name, data.ref)
            files.push(tmp_file)
        })
        return files
    }

    // listen
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let files = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_file = new File(document.id, data.room, data.date, data.type, data.path, data.name, data.ref)
                files.push(tmp_file)
            })
            
            if(callback != null){
                callback(files)
            }
        })
        return unsub
    }
    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let file = new File(snapshot.id, data.room, data.date, data.type, data.path, data.name, data.ref)
            
            if(callback != null){
                callback(file)
            }
        })
        return unsub
    }
    static listenByRoom(roomId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let files = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_file = new File(document.id, data.room, data.date, data.type, data.path, data.name, data.ref)
                files.push(tmp_file)
            })
            
            if(callback != null){
                callback(files)
            }
        })
        return unsub
    }
    
    // edit db
    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }
    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                room : this.room,
                date : this.date,
                type : this.type,
                path : this.path,
                name : this.name,
                ref : this.ref,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                room : this.room,
                date : this.date,
                type : this.type,
                path : this.path,
                name : this.name,
                ref : this.ref,
            })
        }
    }
    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }

    /**
     * get a ref and return if this ref are used elsewhere
     * @param {string} refs
     * @returns boolean
     */
     static async checkIfFileIsUsed(ref, fileId) {
        const collectionRef = collection(db, collectionName)
        const documentsQuery = query(collectionRef,
            where('ref', '==', ref)
        )
        const response = await getDocs(documentsQuery)

        let isUsed = false

        const files = response.docs.filter(item => {
            isUsed = item.id != fileId
            return isUsed
        })

        return isUsed
    }
}

export default File