<template>
    <div v-if="profile && hasCurrentSignature && currentSignature">
        <v-dialog v-model="hasCurrentSignature" persistent max-width="800px">
            <v-card v-if="currentSignature && currentSignature.date">
                <v-card-title class="d-flex align-center justify-center" v-text="'Emargement en cours : ' + new Date(currentSignature.date).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0, 16)"></v-card-title>
                <v-card-text class="pt-3">
                    <span class="d-flex flex-column align-center justify-center">
                        <h3 class="mb-2">Signer sur mon telephone :</h3>
                        <img :src="'https://api.qrserver.com/v1/create-qr-code/?data=' + getSignatureLink()">
                        <a class="pt-3" :href="getSignatureLink()">Impossible de signer sur mon telephone</a>
                        <span class="pt-5">
                            ⚠️En refusant la signature authentifiée par téléphone, vous acceptez que Valy puisse avoir accès à vos données d'utilisation (IP, localisation, temps de consultation, etc.) à des fins anti-fraude afin de garantir la bon déroulement de la certification.
                        </span>
                    </span>

                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import Signature from "@/classes/Signature.js"

    export default {
        name: "SignatureAlert",
        props: ["profile", "user"],
        data() {
            return {
                unsub: [],
                signatures: [],
                hasCurrentSignature: false,
                currentSignature: null,
            }
        },
        created() {
            if(this.profile.role == 'User' && this.profile.currentRoom){
                this.unsub.push(
                    Signature.listenByRoom(this.profile.currentRoom, (signatures) => {
                        signatures.sort((a, b) => {b.date - a.date})
                        this.signatures = signatures

                        this.hasCurrentSignature = false
                        this.currentSignature = null

                        this.signatures.forEach(signature => {
                            if(signature.isOpen && signature.date <= new Date().getTime() && !Object.keys(signature.signatures).includes(this.profile.id)){
                                this.currentSignature = signature
                                this.hasCurrentSignature = true
                            }
                        })
                    }
                ))
            }
        },
        methods: {
            getSignatureLink() {
                return window.location.origin+'/signing/'+this.profile.id+'/'+this.currentSignature.id
            },
        },
        destroyed() {
            this.unsub.forEach((unsub) => unsub())
        },
    }
</script>