import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
const collectionName = envManager.getPrefix() + 'notifications'

const getDataWhere = async (attribute1, symbol1, value1, attribute2, symbol2, value2) => {
    let documentsQuery = collection(db, collectionName)
    if (attribute1 && symbol1 && value1 && attribute2 && symbol2 && value2) {
        documentsQuery = query(documentsQuery,
            where(attribute1, symbol1 , value1),
            where(attribute2, symbol2 , value2)
        )
    } else if (attribute1 && symbol1 && value1) {
        documentsQuery = query(documentsQuery,
            where(attribute1, symbol1 , value1)
        )
    }
    const response = await getDocs(documentsQuery)

    const dataList = []

    response.forEach(document => {
        const data = document.data()
        const tmp_data = new Notification(document.id, data.room, data.date, data.user, data.type, data.content)
        dataList.push(tmp_data)
    })

    return dataList
}
const listenDataWhere = async (callback, attribute1, symbol1, value1, attribute2, symbol2, value2) => {
    let documentsQuery = collection(db, collectionName)
    
    if (attribute1 && symbol1 && value1 && attribute2 && symbol2 && value2) {
        documentsQuery = query(documentsQuery,
            where(attribute1, symbol1, value1),
            where(attribute2, symbol2, value2)
        )
    } else if (attribute1 && symbol1 && value1) {
        documentsQuery = query(documentsQuery,
            where(attribute1, symbol1, value1)
        )
    }

    let unsub = onSnapshot(documentsQuery, snapshot => {
        const dataList = []

        snapshot.forEach(document => {
            const data = document.data()
            const tmp_data = new Notification(document.id, data.room, data.date, data.user, data.type, data.content)
            dataList.push(tmp_data)
        })
        
        if (callback != null) {
            callback(dataList)
        }
    })
    return unsub
}

class Notification{
    constructor(id, room, date, user, type, content){
        this.id = id
        this.room = room
        this.date = date
        this.user = user
        this.type = type
        this.content = content
    }

    // get
    static async getByUser(id){
        return getDataWhere('user', '==', id)
    }
    static async getByRoomAndByUser(room, user){
        return getDataWhere('room', '==', room, 'user', '==', user)
    }
    static async getByRoom(id){
        return getDataWhere('room', '==', id)
    } 
    static async getByContent(id){
        return getDataWhere('content', '==', id)
    }

    // listen
    static listenByUser(id, callback = null) {
        return listenDataWhere(callback, 'user', '==', id)
    }
    static listenByRoomAndByUser(room, user, callback = null) {
        return listenDataWhere(callback, 'room', '==', room, 'user', '==', user)
    }
    
    // edit db
    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }
    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                room : this.room,
                date : this.date,
                user : this.user,
                type : this.type,
                content : this.content,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                room : this.room,
                date : this.date,
                user : this.user,
                type : this.type,
                content : this.content,
            })
        }
    }
    
    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Notification