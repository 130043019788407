import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionPrefix = envManager.getPrefix()
let collectionId = "notes"
let collectionName = collectionPrefix + collectionId

class Note{
    constructor(id, room, name, noteMax, notes, stats, visible, comGlobalVisible = false, comUserVisible = true, comGlobal = "", form  = null, pointsConvertion = null, slideFormNote = false){
        this.id = id
        this.room = room
        this.name = name
        this.noteMax = noteMax
        this.notes = notes
        this.stats = stats
        this.visible = visible
        this.comGlobalVisible = comGlobalVisible
        this.comUserVisible = comUserVisible
        this.comGlobal = comGlobal
        this.form = form
        this.pointsConvertion = pointsConvertion
        this.slideFormNote = slideFormNote
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let notes = []

        response.forEach(document => {
            let data = document.data()
            let tmp_note = new Note(document.id, data.room, data.name, data.noteMax, data.notes, data.stats, data.visible, data.comGlobalVisible, data.comUserVisible, data.comGlobal, data.form, data.pointsConvertion, data.slideFormNote)
            notes.push(tmp_note)
        })

        return notes
    }
    
    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let note = new Note(response.id, data.room, data.name, data.noteMax, data.notes, data.stats, data.visible, data.comGlobalVisible, data.comUserVisible, data.comGlobal, data.form, data.pointsConvertion, data.slideFormNote)

        return note
    }

    static async getByRoom(roomId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
        let response = await getDocs(documentsQuery)

        let notes = []

        response.forEach(document => {
            let data = document.data()
            let tmp_note = new Note(document.id, data.room, data.name, data.noteMax, data.notes, data.stats, data.visible, data.comGlobalVisible, data.comUserVisible, data.comGlobal, data.form, data.pointsConvertion, data.slideFormNote)
            notes.push(tmp_note)
        })
        return notes
    } 

    static async getByForm(formId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("form", "==", formId))
        let response = await getDocs(documentsQuery)

        let notes = []

        response.forEach(document => {
            let data = document.data()
            let tmp_note = new Note(document.id, data.room, data.name, data.noteMax, data.notes, data.stats, data.visible, data.comGlobalVisible, data.comUserVisible, data.comGlobal, data.form, data.pointsConvertion, data.slideFormNote)
            notes.push(tmp_note)
        })
        return notes
    }

    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let notes = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_note = new Note(document.id, data.room, data.name, data.noteMax, data.notes, data.stats, data.visible, data.comGlobalVisible, data.comUserVisible, data.comGlobal, data.form, data.pointsConvertion, data.slideFormNote)
                notes.push(tmp_note)
            })
            
            if(callback != null){
                callback(notes)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let note = new Note(snapshot.id, data.room, data.name, data.noteMax, data.notes, data.stats, data.visible, data.comGlobalVisible, data.comUserVisible, data.comGlobal, data.form, data.pointsConvertion, data.slideFormNote)
            
            if(callback != null){
                callback(note)
            }
        })
        return unsub
    }

    static listenByRoom(roomId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let notes = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_note = new Note(document.id, data.room, data.name, data.noteMax, data.notes, data.stats, data.visible, data.comGlobalVisible, data.comUserVisible, data.comGlobal, data.form, data.pointsConvertion, data.slideFormNote)
                notes.push(tmp_note)
            })
            
            if(callback != null){
                callback(notes)
            }
        })
        return unsub
    }
    
    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                room : this.room,
                name : this.name,
                noteMax : this.noteMax,
                notes : this.notes,
                stats : this.stats,
                visible : this.visible,
                comGlobalVisible : this.comGlobalVisible,
                comUserVisible : this.comUserVisible,
                comGlobal : this.comGlobal,
                form : this.form,
                pointsConvertion: this.pointsConvertion,
                slideFormNote: this.slideFormNote
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                room : this.room,
                name : this.name,
                noteMax : this.noteMax,
                notes : this.notes,
                stats : this.stats,
                visible : this.visible,
                comGlobalVisible : this.comGlobalVisible,
                comUserVisible : this.comUserVisible,
                comGlobal : this.comGlobal,
                form : this.form,
                pointsConvertion: this.pointsConvertion,
                slideFormNote: this.slideFormNote
            })
        }
    }
    
    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Note