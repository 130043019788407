<template>
	<div id="misc">
		<div class="page-title text-center px-4">
			<h2 class="text-3xl font-weight-semibold text--primary d-flex align-center justify-center">
				<span class="me-2">{{ config.shutdownTitle }}</span>
			</h2>
			<p class="mt-3 text-sm">{{ config.shutdownMessage }}</p>

			<div class="misc-character d-flex justify-center">
				<v-img height="100%" max-width="700" src="@/assets/images/3d-characters/error-shutdown.png"></v-img>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ['config']
}
</script>
