import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

import CryptoJS from "crypto-js"

import Mail from "@/classes/Mail"
import Note from "@/classes/Note"

let db = getFirestore()
let collectionPrefix = envManager.getPrefix()
let collectionId = "forms"
let collectionName = collectionPrefix + collectionId

let CryptoKey = "9a8cda40689d57f401ac182f034573d20360db3c62bb3338f83e8c11bd83bdd18440d1b066a0387d0a466bb945c8b6741d6d11dffa154a48b5c0fae755477b86"

class Form{
    constructor(id, room, title, questions, rdmOrder = {question: false, answer: false}){
        this.id = id
        this.room = room
        this.title = title
        this.questions = questions
        this.rdmOrder = rdmOrder
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let forms = []

        response.forEach(document => {
            let data = document.data()
            let tmp_form = new Form(document.id, data.room, data.title, data.questions, data.rdmOrder)
            forms.push(tmp_form)
        })

        return forms
    }
    
    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let form = new Form(response.id, data.room, data.title, data.questions, data.rdmOrder)

        return form
    }

    static async getByRoom(roomId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
        let response = await getDocs(documentsQuery)

        let forms = []

        response.forEach(document => {
            let data = document.data()
            let tmp_form = new Form(document.id, data.room, data.title, data.questions, data.rdmOrder)
            forms.push(tmp_form)
        })
        return forms
    } 

    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let forms = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_form = new Form(document.id, data.room, data.title, data.questions, data.rdmOrder)
                forms.push(tmp_form)
            })
            
            if(callback != null){
                callback(forms)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let form = new Form(snapshot.id, data.room, data.title, data.questions, data.rdmOrder)
            
            if(callback != null){
                callback(form)
            }
        })
        return unsub
    }

    static listenByRoom(roomId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let forms = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_form = new Form(document.id, data.room, data.title, data.questions, data.rdmOrder)
                forms.push(tmp_form)
            })
            
            if(callback != null){
                callback(forms)
            }
        })
        return unsub
    }
    
    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }

    encode(){
        let tmp_questions = []
        for(let question of this.questions){
            tmp_questions.push(CryptoJS.AES.encrypt(JSON.stringify(question), CryptoKey).toString())
        }
        this.questions = tmp_questions
    }

    decode(){
        let tmp_questions = []
        for(let question of this.questions){
            tmp_questions.push(JSON.parse(CryptoJS.AES.decrypt(question, CryptoKey).toString(CryptoJS.enc.Utf8)))
        }
        this.questions = tmp_questions
    }

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                room: this.room,
                title: this.title,
                questions: this.questions,
                rdmOrder: this.rdmOrder
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                room: this.room,
                title: this.title,
                questions: this.questions,
                rdmOrder: this.rdmOrder
            })
        }
    }
    
    delete(){
        Mail.getByForm(this.id)
        .then(responses => {
            responses.forEach(response => {
                response.delete()
            })

            Note.getByForm(this.id)
            .then(responses => {
                responses.forEach(response => {
                    response.delete()
                })
                
                let documentRef = doc(db, collectionName, this.id)
                deleteDoc(documentRef)
            })
        })
    }
}

export default Form