const config = {
  main : {
    apiKey: 'AIzaSyDH-TjAl2xE2RC5rPvACzHpP7g9aSo-6TU',
    authDomain: 'loesus-pedagogia.firebaseapp.com',
    projectId: 'loesus-pedagogia',
    storageBucket: 'loesus-pedagogia.appspot.com',
    messagingSenderId: '993307679620',
    appId: '1:993307679620:web:181e8428ec8a6525901bd1',
    measurementId: 'G-YBR6SPGDC4',
  }
}

export default config
