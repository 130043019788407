import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

import MailResponse from "@/classes/MailResponse"

let db = getFirestore()
let collectionPrefix = envManager.getPrefix()
let collectionId = "mails"
let collectionName = collectionPrefix + collectionId

class Mail{
    constructor(id, room, date, responseType, sender, receiver = 'all', urgent, subject, message, attachments = [], form = null, showCorrection = null, uniqueAnswer = true){
        this.id = id
        this.room = room
        this.date = date
        this.responseType = responseType
        this.sender = sender
        this.receiver = receiver
        this.urgent = urgent
        this.subject = subject
        this.message = message
        this.attachments = attachments
        this.form = form
        this.showCorrection = showCorrection
        this.uniqueAnswer = uniqueAnswer
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let mails = []

        response.forEach(document => {
            let data = document.data()
            let tmp_mail = new Mail(document.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
            mails.push(tmp_mail)
        })

        return mails
    }
    
    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()

        return (!data) ? null : new Mail(response.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
    }

    static async getByRoom(roomId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
        let response = await getDocs(documentsQuery)

        let mails = []

        response.forEach(document => {
            let data = document.data()
            let tmp_mail = new Mail(document.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
            mails.push(tmp_mail)
        })
        return mails
    }

    static async getByForm(formId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("form", "==", formId))
        let response = await getDocs(documentsQuery)

        let mails = []

        response.forEach(document => {
            let data = document.data()
            let tmp_mail = new Mail(document.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
            mails.push(tmp_mail)
        })
        return mails
    }

    static async getOnlyFormByRoom(roomId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("responseType", "==", "form"))
        let response = await getDocs(documentsQuery)

        let mails = []

        response.forEach(document => {
            let data = document.data()
            let tmp_mail = new Mail(document.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
            mails.push(tmp_mail)
        })
        return mails
    } 

    static async getBySender(senderId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("sender", "==", senderId))
        let response = await getDocs(documentsQuery)

        let mails = []

        response.forEach(document => {
            let data = document.data()
            let tmp_mail = new Mail(document.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
            mails.push(tmp_mail)
        })
        return mails
    }

    static async getByRoomAndBySender(roomId, senderId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("sender", "==", senderId))
        let response = await getDocs(documentsQuery)

        let mails = []

        response.forEach(document => {
            let data = document.data()
            let tmp_mail = new Mail(document.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
            mails.push(tmp_mail)
        })
        return mails
    }
    static async getByRoomAndByReceiver(roomId, receiverId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("receiver", "==", receiverId))
        let response = await getDocs(documentsQuery)

        let mails = []

        response.forEach(document => {
            let data = document.data()
            let tmp_mail = new Mail(document.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
            mails.push(tmp_mail)
        })
        return mails
    }

    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let mails = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_mail = new Mail(document.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
                mails.push(tmp_mail)
            })
            
            if(callback != null){
                callback(mails)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let mail = new Mail(snapshot.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
            
            if(callback != null){
                callback(mail)
            }
        })
        return unsub
    }

    static listenByRoom(roomId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let mails = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_mail = new Mail(document.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
                mails.push(tmp_mail)
            })
            
            if(callback != null){
                callback(mails)
            }
        })
        return unsub
    }

    static listenOnlyFormByRoom(roomId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("responseType", "==", "form"))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let mails = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_mail = new Mail(document.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
                mails.push(tmp_mail)
            })
            
            if(callback != null){
                callback(mails)
            }
        })
        return unsub
    }

    static listenBySender(senderId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("sender", "==", senderId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let mails = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_mail = new Mail(document.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
                mails.push(tmp_mail)
            })
            
            if(callback != null){
                callback(mails)
            }
        })
        return unsub
    }

    static listenByRoomAndBySender(roomId, senderId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("sender", "==", senderId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let mails = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_mail = new Mail(document.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
                mails.push(tmp_mail)
            })
            
            if(callback != null){
                callback(mails)
            }
        })
        return unsub
    }
    static listenByRoomAndByReceiver(roomId, receiverId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("receiver", "==", receiverId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let mails = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_mail = new Mail(document.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
                mails.push(tmp_mail)
            })
            
            if(callback != null){
                callback(mails)
            }
        })
        return unsub
    }
    static listenByUserReceived(user, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", user.currentRoom), where("receiver", "in", [user.id, 'all']))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let mails = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_mail = new Mail(document.id, data.room, data.date, data.responseType, data.sender, data.receiver, data.urgent, data.subject, data.message, data.attachments, data.form, data.showCorrection, data.uniqueAnswer)
                mails.push(tmp_mail)
            })
            
            if(callback != null){
                callback(mails)
            }
        })
        return unsub
    }
    
    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                room: this.room,
                date: this.date,
                responseType: this.responseType,
                sender: this.sender,
                receiver: this.receiver,
                urgent: this.urgent,
                subject: this.subject,
                attachments: this.attachments,
                message: this.message,
                form: this.form,
                showCorrection: this.showCorrection,
                uniqueAnswer: this.uniqueAnswer
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                room: this.room,
                date: this.date,
                responseType: this.responseType,
                sender: this.sender,
                receiver: this.receiver,
                urgent: this.urgent,
                subject: this.subject,
                attachments: this.attachments,
                message: this.message,
                form: this.form,
                showCorrection: this.showCorrection,
                uniqueAnswer: this.uniqueAnswer
            })
        }
    }
    
    delete(){
        MailResponse.getByMail(this.id)
        .then(responses => {
            responses.forEach(response => {
                response.delete()
            })

            let documentRef = doc(db, collectionName, this.id)
            deleteDoc(documentRef)
        })
    }


    /**
     * get a list of refs and return if these refs are used elsewhere
     * @param {Array} refs 
     * @returns Array(boolean)
     */
    static async checkIfAttachIsUsed(refs, roomId) {
        const collectionRef = collection(db, collectionName)
        const documentsQuery = query(collectionRef,
            where('room', '!=', roomId)// ,
            // where('attachments', 'not-in', [[], null])
        )
        const response = await getDocs(documentsQuery)

        const isUsed = refs.map(item => false)

        // 'mails' = mails.includes(1 or more attachment where ref is in 'refs')
        const mails = response.docs.filter(item => {
            item = item.data()
            if (item.attachments && item.attachments.length != undefined && item.attachments.length > 0) {
                for (const attach of item.attachments) {
                    if (refs.includes(attach.ref)) {
                        isUsed[refs.indexOf(attach.ref)] = true
                    }
                }
            }
            return isUsed.includes(true)
        })

        return isUsed
    }
}

export default Mail