import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

const db = getFirestore()
const collectionPrefix = envManager.getPrefix()
const collectionId = "groups"
const collectionName = collectionPrefix + collectionId

/**
 * get fireBaseDocs and return usable data
 * @param {fireBaseDocs} response 
 * @returns usable data
 */
const toValy = response => {
    const lists = []
    response.forEach(document => {
        const data = document.data()
        lists.push(new Group(
            document.id,
            data.room,
            data.name,
            data.members
        ))
    })
    return lists
}

class Group{
    constructor(id, room, name, members){
        this.id = id
        this.room = room
        this.name = name
        this.members = members
    }

    async save(){
        const collectionRef = collection(db, collectionName)

        if(this.id == null){
            const response = await addDoc(collectionRef, {
                room: this.room,
                name: this.name,
                members: this.members
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                room: this.room,
                name: this.name,
                members: this.members
            })
        }
    }
    async delete(){
        const documentRef = doc(db, collectionName, this.id)
        await deleteDoc(documentRef)
    }

    // get
    static async getAll(){
        const collectionRef = collection(db, collectionName)
        const response = await getDocs(collectionRef)

        return toValy(response)
    }
    static async getByRoom(roomId){
        const collectionRef = collection(db, collectionName)
        const documentsQuery = query(collectionRef, where('room', '==', roomId))
        const response = await getDocs(documentsQuery)

        return toValy(response)
    }
    static async getByRoomAndByOneMember(roomId, memberId){
        const collectionRef = collection(db, collectionName)
        const documentsQuery = query(collectionRef, where('room', '==', roomId), where('members', 'array-contains', memberId))
        const response = await getDocs(documentsQuery)

        return toValy(response)
    }

    // listen
    static listenAll(callback = null){
        const collectionRef = collection(db, collectionName)

        const unsub = onSnapshot(collectionRef, snapshot => {
            const data = toValy(snapshot)
            if (callback != null) {
                callback(data)
            }
        })
        return unsub
    }
    static listenByRoom(roomId, callback = null){
        const collectionRef = collection(db, collectionName)
        const documentsQuery = query(collectionRef, where('room', '==', roomId))
    
        const unsub = onSnapshot(documentsQuery, snapshot => {
            const data = toValy(snapshot)
            if (callback != null) {
                callback(data)
            }
        })
        return unsub
    }
    static listenByRoomAndByOneMember(roomId, memberId, callback = null){
        const collectionRef = collection(db, collectionName)
        const documentsQuery = query(collectionRef, where('room', '==', roomId), where('members', 'array-contains', memberId))
    
        const unsub = onSnapshot(documentsQuery, snapshot => {
            const data = toValy(snapshot)
            if (callback != null) {
                callback(data)
            }
        })
        return unsub
    }
}

export default Group