<template>
    <v-snackbar :timeout="-1" v-model="visible" v-if="alert">
        <v-alert :type="alert.type ? alert.type : undefined">
            <div class="d-flex align-center justify-end">
                <div v-text="alert.text" class="mr-5 background--text" style="width:100%"></div>
                <v-btn icon outlined color="customWhite" @click="hideAnAlert">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
        </v-alert>
    </v-snackbar>
    <div v-else-if="SignatureNeedToBeCloseAlert && ['SuperAdmin', 'Admin'].includes(profile.role) && !SignatureNeedToBeCloseAlertHiddenRoute.includes($router.currentRoute.name)" @click="redirect('/signatures')">
        <v-snackbar :timeout="-1" v-model="SignatureNeedToBeCloseAlert" top class="mt-5">
            <v-alert color="primary" class="pointer">
                <div class="d-flex align-center justify-end background--text">
                    Un émargement a besoin d'être validé. (Séance terminée)
                </div>
            </v-alert>
        </v-snackbar>
    </div>
</template>

<script>
import notifier from "@/assets/functions/notifier"

export default {
    props: ['profile', 'alert', 'stayHidden', 'SignatureNeedToBeCloseAlert'],
    data: () => ({
        SignatureNeedToBeCloseAlertHiddenRoute: ['signatures'],
        visible: false
    }),
    watch: {
        $route: {
            handler() {
                this.$forceUpdate()
            },
            deep: true
        }
    },
    created() {
        if (!this.stayHidden && this.alert) {
            setTimeout(() => {
                this.visible = true
                notifier.sound(this.profile.notification, this.profile.notificationVolume)
            }, this.alert.date - new Date().getTime())
        }
    },
    methods: {
        hideAnAlert(){
            this.visible = false
            if (this.alert.id) {
                this.alert.hide.push(this.profile.id)
                this.alert.save()
            }
        },
        redirect(path) {
            if ('/' + this.$router.currentRoute.name != path)
                this.$router.push(path)
        }
    }
}
</script>

<style>
.v-snack{
    padding-bottom: 15px !important;
}
.v-snack__wrapper{
    margin: 0 !important;
}
.v-snack__content{
    padding: 0 !important;
}
.v-alert{
    margin: 0 !important;
    margin-right: -8px !important;
}
.v-alert_wrapper{
    display: flex;
    align-items: center;
}
.v-alert__icon{
    align-self: unset !important;
}
</style>