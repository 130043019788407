<template>
    <!-- outlined -->
    <v-menu offset-y left nudge-bottom="14" origin="top right" transition="scale-transition">
		<template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" class="mr-3 px-4">
                <v-icon size="22" class="mr-3">mdi-google-classroom</v-icon>
                <div v-text="currentRoom.name"></div>
            </v-btn>
		</template>

		<v-list>
            <div class="d-flex justify-center mt-2" @click.prevent.stop>
                <p class="px-3" style="font-size:0.80em" v-text="'Room actuelle : ' + currentRoom.name"></p>
            </div>

			<v-divider class="mb-2"></v-divider>

			<v-list-item v-for="room in availableRooms" @click.prevent="changeRoom(room)">
				<v-list-item-content>
					<v-list-item-title v-text="room.name"></v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
	</v-menu>
</template>
<script>
    import Room from '@/classes/Room'

    export default {
        name: 'AppBarRoomChange',
        props:['profile'],
        data() {
            return {
                roomsTable : {},
                unsub : [],
                currentRoom: {}
            }
        },
        created() {
            this.unsub.push(Room.listenAll(rooms => {
                let tmp_association_rooms = {}
                rooms.forEach(room => {
                    tmp_association_rooms[room.id] = room
                    if(room.id == this.profile.currentRoom) this.currentRoom = room
                })
                this.roomsTable = tmp_association_rooms
            }))
        },
        computed: {
            availableRooms() {
                let tmp_availableRooms = []
                for(let room in this.roomsTable) {
                    if(['SuperAdmin', 'Admin'].includes(this.profile.role) || (this.profile.rooms && this.profile.rooms.includes(room))) {
                        if(this.roomsTable[room].id != this.currentRoom.id) tmp_availableRooms.push(this.roomsTable[room])
                    }
                }
                tmp_availableRooms.sort((a, b) => {
                    if(a.name < b.name) return -1
                    if(a.name > b.name) return 1
                    return 0
                })
                return tmp_availableRooms
            }
        },
        methods: {
            changeRoom(room){
                let tmp_profile = this.profile
                tmp_profile.currentRoom = room.id
                tmp_profile.save()
                .then(() => {
                    window.location.reload()
                })
            }
        },
        destroyed() {
            this.unsub.forEach(unsub => unsub())
        }
    }
</script>