import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionPrefix = envManager.getPrefix()
let collectionId = "events"
let collectionName = collectionPrefix + collectionId

class Event{
    constructor(id, room, type, name, start, end, user = null, link = {name:null, url:null}, metadata = {}){
        this.id = id
        this.room = room
        this.type = type
        this.name = name
        this.start = start
        this.end = end
        this.user = user
        this.link = link
        this.metadata = metadata
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let events = []

        response.forEach(document => {
            let data = document.data()
            let tmp_event = new Event(document.id, data.room, data.type, data.name, data.start, data.end, data.user, data.link, data.metadata)
            events.push(tmp_event)
        })

        return events
    }
    
    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()

        return (!data) ? null :new Event(response.id, data.room, data.type, data.name, data.start, data.end, data.user, data.link, data.metadata)
    }
    
    static async getByRoom(roomId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
        let response = await getDocs(documentsQuery)

        let events = []

        response.forEach(document => {
            let data = document.data()
            let tmp_event = new Event(document.id, data.room, data.type, data.name, data.start, data.end, data.user, data.link, data.metadata)
            events.push(tmp_event)
        })
        return events
    }
    
    static async getByRoomAndByType(roomId, type){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("type", "==", type))
        let response = await getDocs(documentsQuery)

        let events = []

        response.forEach(document => {
            let data = document.data()
            let tmp_event = new Event(document.id, data.room, data.type, data.name, data.start, data.end, data.user, data.link, data.metadata)
            events.push(tmp_event)
        })
        return events
    } 

    static async getByRoomByTypeAndByUser(roomId, type, userId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("type", "==", type), where("user", "==", userId))
        let response = await getDocs(documentsQuery)

        let events = []

        response.forEach(document => {
            let data = document.data()
            let tmp_event = new Event(document.id, data.room, data.type, data.name, data.start, data.end, data.user, data.link, data.metadata)
            events.push(tmp_event)
        })
        return events
    } 

    static async getByUser(userId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("user", "==", userId))
        let response = await getDocs(documentsQuery)

        let events = []

        response.forEach(document => {
            let data = document.data()
            let tmp_event = new Event(document.id, data.room, data.type, data.name, data.start, data.end, data.user, data.link, data.metadata)
            events.push(tmp_event)
        })
        return events
    } 

    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let events = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_event = new Event(document.id, data.room, data.type, data.name, data.start, data.end, data.user, data.link, data.metadata)
                events.push(tmp_event)
            })
            
            if(callback != null){
                callback(events)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let event = new Event(snapshot.id, data.room, data.type, data.name, data.start, data.end, data.user, data.link, data.metadata)
            
            if(callback != null){
                callback(event)
            }
        })
        return unsub
    }

    static listenByRoomAndByType(roomId, type, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("type", "==", type))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let events = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_event = new Event(document.id, data.room, data.type, data.name, data.start, data.end, data.user, data.link, data.metadata)
                events.push(tmp_event)
            })
            
            if(callback != null){
                callback(events)
            }
        })
        return unsub
    }

    static listenByRoomByTypeAndByUser(roomId, type, userId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("type", "==", type), where("user", "==", userId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let events = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_event = new Event(document.id, data.room, data.type, data.name, data.start, data.end, data.user, data.link, data.metadata)
                events.push(tmp_event)
            })
            
            if(callback != null){
                callback(events)
            }
        })
        return unsub
    }
    
    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                room : this.room,
                type : this.type,
                name : this.name,
                start : this.start,
                end : this.end,
                user : this.user,
                link : this.link,
                metadata : this.metadata,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                room : this.room,
                type : this.type,
                name : this.name,
                start : this.start,
                end : this.end,
                user : this.user,
                link : this.link,
                metadata : this.metadata,
            })
        }
    }
    
    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Event