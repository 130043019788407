import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionPrefix = envManager.getPrefix()
let collectionId = "phoneResponse"
let collectionName = collectionPrefix + collectionId

class PhoneResponse{
    constructor(id, message, date, sender, text, attachments = []){
        this.id = id
        this.message = message
        this.date = date
        this.sender = sender
        this.text = text
        this.attachments = attachments
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let phoneResponses = []

        response.forEach(document => {
            let data = document.data()
            let tmp_phoneResponse = new PhoneResponse(document.id, data.message, data.date, data.sender, data.text, data.attachments)
            phoneResponses.push(tmp_phoneResponse)
        })

        return phoneResponses
    }
    
    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let phoneResponse = new PhoneResponse(response.id, data.message, data.date, data.sender, data.text, data.attachments)

        return phoneResponse
    }

    static async getByPhone(phoneId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("message", "==", phoneId))
        let response = await getDocs(documentsQuery)

        let phoneResponses = []

        response.forEach(document => {
            let data = document.data()
            let tmp_phoneResponse = new PhoneResponse(document.id, data.message, data.date, data.sender, data.text, data.attachments)
            phoneResponses.push(tmp_phoneResponse)
        })
        return phoneResponses
    } 

    static async getBySender(senderId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("sender", "==", senderId))
        let response = await getDocs(documentsQuery)

        let phoneResponses = []

        response.forEach(document => {
            let data = document.data()
            let tmp_phoneResponse = new PhoneResponse(document.id, data.message, data.date, data.sender, data.text, data.attachments)
            phoneResponses.push(tmp_phoneResponse)
        })
        return phoneResponses
    } 

    static async getByPhoneAndSender(phoneId, senderId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("message", "==", phoneId), where("sender", "==", senderId))
        let response = await getDocs(documentsQuery)

        let phoneResponse = null
        let firstTime = true
        response.forEach(document => {
            if(firstTime){
                let data = document.data()
                phoneResponse = new PhoneResponse(document.id, data.message, data.date, data.sender, data.text, data.attachments)
                firstTime = false
            }else{
                let documentRef = doc(db, collectionName, document.id)
                deleteDoc(documentRef)
            }
        })
        return phoneResponse
    } 

    // listen
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let phoneResponses = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_phoneResponse = new PhoneResponse(document.id, data.message, data.date, data.sender, data.text, data.attachments)
                phoneResponses.push(tmp_phoneResponse)
            })
            
            if(callback != null){
                callback(phoneResponses)
            }
        })
        return unsub
    }
    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let phoneResponse = new PhoneResponse(snapshot.id, data.message, data.date, data.sender, data.text, data.attachments)
            
            if(callback != null){
                callback(phoneResponse)
            }
        })
        return unsub
    }
    static listenByPhone(phoneId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("message", "==", phoneId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let phoneResponses = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_phoneResponse = new PhoneResponse(document.id, data.message, data.date, data.sender, data.text, data.attachments)
                phoneResponses.push(tmp_phoneResponse)
            })
            
            if(callback != null){
                callback(phoneResponses)
            }
        })
        return unsub
    }
    static listenBySender(senderId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("sender", "==", senderId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let phoneResponses = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_phoneResponse = new PhoneResponse(document.id, data.message, data.date, data.sender, data.text, data.attachments)
                phoneResponses.push(tmp_phoneResponse)
            })
            
            if(callback != null){
                callback(phoneResponses)
            }
        })
        return unsub
    }
    static listenByPhoneAndSender(senderId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("message", "==", phoneId), where("sender", "==", senderId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let phoneResponse = null
            let firstTime = true
            snapshot.forEach(document => {
                if(firstTime){
                    let data = document.data()
                    phoneResponse = new PhoneResponse(document.id, data.message, data.date, data.sender, data.text, data.attachments)
                    firstTime = false
                }else{
                    let documentRef = doc(db, collectionName, document.id)
                    deleteDoc(documentRef)
                }
            })
            
            if(callback != null){
                callback(phoneResponse)
            }
        })
        return unsub
    }
    static listenByRoomAndBySender(roomId, senderId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("sender", "==", senderId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let phoneResponses = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_phoneResponse = new PhoneResponse(document.id, data.message, data.date, data.sender, data.text, data.attachments)
                phoneResponses.push(tmp_phoneResponse)
            })
            
            if(callback != null){
                callback(phoneResponses)
            }
        })
        return unsub
    }
    
    // edit db
    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }
    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                message: this.message,
                date: this.date,
                sender: this.sender,
                text: this.text,
                attachments: this.attachments,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                message: this.message,
                date: this.date,
                sender: this.sender,
                text: this.text,
                attachments: this.attachments,
            })
        }
    }
    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default PhoneResponse