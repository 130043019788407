import Profile from "@/classes/Profile"
import Notification from "@/classes/Notification"

import notif1 from "@/assets/sounds/notifications/notif1.wav"
import notif2 from "@/assets/sounds/notifications/notif2.wav"
import notif3 from "@/assets/sounds/notifications/notif3.wav"
import notif4 from "@/assets/sounds/notifications/notif4.wav"
import notif5 from "@/assets/sounds/notifications/notif5.wav"
import notif6 from "@/assets/sounds/notifications/notif6.wav"
import notif7 from "@/assets/sounds/notifications/notif7.wav"

const notifier = {
    async send(roomId, type, date, content){
        const [profiles, notifications] = await Promise.all([Profile.getByRoom(roomId), Notification.getByContent(content)])

        for (const notif of notifications) {
            notif.delete()
        }

        for (const profile of profiles) {
            if (profile.role === 'User') {
                let new_notification = new Notification(null, roomId, date, profile.id, type, content)
                new_notification.save()
            }
        }
    },
    
    async sendOne(profileID, roomId, type, date, content, stackable = null) {
        if (!stackable) { // need stackable notif for message, the "content" is a user
            const notifications = await Notification.getByContent(content)
            for (const notif of notifications) {
                notif.delete()
            }
        }

        const new_notification = new Notification(null, roomId, date, profileID, type, content)
        new_notification.save()
    },

    sound(nb, volume = 100) {
        let audio
        switch (nb) {
            case 2:
                audio = new Audio(notif2)
                break
            case 3:
                audio = new Audio(notif3)
                break
            case 4:
                audio = new Audio(notif4)
                break
            case 5:
                audio = new Audio(notif5)
                break
            case 6:
                audio = new Audio(notif6)
                break
            case 7:
                audio = new Audio(notif7)
                break
            default:
                audio = new Audio(notif1)
                break
        }
        audio.volume = volume / 100
        audio.play()
    }
}

export default notifier
