<template>
  <div class="wrapper" :class="getClass()">
    <v-btn large icon v-if="$vuetify.breakpoint.smAndDown" @click="isClose = false" class="hamb-btn">
      <v-icon v-if="nbNotif === 0">mdi-menu</v-icon>
      <v-badge v-else color="notif" :content="nbNotif">
        <v-icon>mdi-menu</v-icon>
      </v-badge>
    </v-btn>
    <nav :class="getClass()" @mouseenter="isHover = true" @mouseleave="isHover = false">
      <!-- top -->
      <div class="top-section">
        <v-btn large icon v-if="$vuetify.breakpoint.smAndDown" @click="isClose = true">
          <v-icon large>mdi-close</v-icon>
        </v-btn>
        <img :src="isDark ? appDarkLogo : appLogo">
        <div class="checkbox" v-if="!$vuetify.breakpoint.smAndDown">
          <v-checkbox v-model="isLarge"
            class="ma-0 pa-0" hide-details
            on-icon="mdi-circle-slice-8"
            off-icon="mdi-circle-outline"
          />
        </div>
      </div>

      <!-- menu -->
      <v-list :shaped="!$vuetify.breakpoint.name !== 'xs'" class="ma-0 pa-0" color="transparent">
        <template v-for="item of items" v-if="checkIfItemIsDisplay(item)">
          <!-- classic -->
          <v-list-item v-if="!item.children || item.children.length <= 0"
            @click="redirect(item.to)"
            link class="list-item"
            :class="checkIfActive(item) ? 'isActive' : ''"
          >
            <v-list-item-icon class="mr-3">
              <v-icon size="20" v-if="nbNotification(item) === 0">{{ item.icon }}</v-icon>
              <v-badge v-else color="notif" offset-x="10" offset-y="10" :content="nbNotification(item)">
                <v-icon>{{ item.icon }}</v-icon>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>

          <!-- group -->
          <v-list-group v-else
            v-model="groupIsOpen[item.title]"
            :append-icon="isLarge || isHover || $vuetify.breakpoint.smAndDown ? 'mdi-chevron-down' : ''"
          >
            <template #activator>
              <div class="list-item">
                <v-list-item-icon class="mr-3">
                  <v-icon size="20" v-if="nbNotification(item) === 0">{{ item.icon }}</v-icon>
                  <v-badge v-else color="notif" offset-x="10" offset-y="10" :content="nbNotification(item)">
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-badge>
                </v-list-item-icon>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </div>
            </template>

            <!-- childrens -->
            <v-list-item v-for="child of item.children" :key="child.title"
              v-if="checkIfItemIsDisplay(child)"
              @click="redirect(child.to)"
              link class="pl-12 list-item"
              :class="checkIfActive(child) ? 'isActive' : ''"
            >
              <v-list-item-icon class="mr-3">
                <v-icon size="20" v-if="nbNotification(child) === 0">{{ child.icon }}</v-icon>
                <v-badge v-else color="notif" offset-x="10" offset-y="10" :content="nbNotification(child)">
                  <v-icon>{{ child.icon }}</v-icon>
                </v-badge>
              </v-list-item-icon>
              <v-list-item-title class="text-subtitle-2 font-weight-regular">
                {{ child.title }}
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </nav>
  </div>
</template>

<script>
import themeConfig from '@themeConfig'
import items from '@/assets/data/navigationItems'

export default {
  name: 'navigation',
  props: ['profile', 'currentRoom', 'notifications'],
  data() {
    return {
      groupIsOpen: {},
      lastOpen: null,
      isClose: true,
      isLarge: true,
      isHover: false,
      isDark: themeConfig.app.isDark,
      appLogo: themeConfig.app.logo,
      appDarkLogo: themeConfig.app.darkLogo,

      items,
      routes: this.$router.options.routes,

      nbNotif: 0
    }
  },
  watch: {
    isHover(newVal) {
      if (this.isLarge === false) {
        if (newVal) {
          if (this.lastOpen) {
            this.groupIsOpen[this.lastOpen] = true
          }
        } else {
          for (const key in this.groupIsOpen) {
            if (this.groupIsOpen[key] === true) {
              this.lastOpen = key
            }
            this.groupIsOpen[key] = false
          }
        }
      }
    },
    notifications() {
      this.$forceUpdate()
    },
    $route() {
      this.$forceUpdate()
    }
  },
  methods: {
    redirect(path) {
      if (this.$router.currentRoute.path !== '/'+path) {
        this.isClose = true
        this.$router.push(path)
      }
    },
    getClass() {
      let classes = ''

      if (this.$vuetify.breakpoint.name === 'xs') {
        classes += 'mobile'
      } else if (this.$vuetify.breakpoint.name === 'sm') {
        classes += 'hidden'
      } else {
        if (this.isLarge) {
          classes += 'large'
        } else {
          classes += 'small'
        }
      }

      if (this.isClose) {
        classes += ' isClose'
      }

      return classes
    },
    checkIfActive(item) {
      if ('/'+item.to === this.$router.currentRoute.path) {
        return true
      }
      return false
    },
    checkIfItemIsDisplay(item) {
        if (item.children) {
            return item.children.some(child => this.checkIfItemIsDisplay(child))
        } else {
          const route = this.routes.find(route => route.path === '/'+item.to)
          if (!route.meta.authLevel || (route.meta.authLevel[this.profile.role] || (route.meta.authLevel.dev && this.profile.dev))) {
            return true
          }
        }
        return false
    },
    nbNotification(item) {
      let nb = 0

      if (item.children) {
        for (const child of item.children) {
          nb += this.nbNotification(child)
        }
      } else {
        if (item.notifType) {
          for (const type of item.notifType) {
            if (this.notifications[type]) {
              nb += this.notifications[type].length
            }
          }
        }
      }
      return nb
    }
  }
}
</script>

<style scoped lang="scss">
$large-nav-size: 250px;
$small-nav-size: 75px;

$large-image-size: 150px;
$small-image-size: 50px;

$transition-speed: 200ms;
$transition-speed-mobile: 400ms;

// global
nav {
  position: fixed;
  top: 0;
  height: 100%;
  padding-right: 12px;
  background-color: var(--v-scrollBg-base);
  .top-section {
    display: grid;
    justify-content: space-between;
    padding: 20px 20px 8px 24px;
    img {
      transition: $transition-speed;
    } .checkbox {
      transition: $transition-speed;
    }
  }

  .list-item {
    width: 100%;
    display: grid;
    transition: $transition-speed;
  }

  .isActive {
    background-color: var(--v-primary-base);
    i, div {
      color: white;
    }
  }
}

// mobile <600px
nav.mobile {
  width: 100%;
  transition: $transition-speed-mobile;

  &.isClose {
    transform: translateY(-100%);
  }

  .top-section {
    grid-template-columns: 1fr auto;
    img {
      width: $large-image-size;
      max-width: $large-image-size;
      max-height: $large-image-size;
    }
  }

  .list-item {
    grid-template-columns: auto 1fr 0px;
  }
}

// hidden >600px <960px
nav.hidden {
  transition: $transition-speed-mobile;

  &.isClose {
    transform: translateX(-100%);
  } &:not(&.isClose) {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  .top-section {
    grid-template-columns: 1fr auto;
    img {
      width: $large-image-size;
      max-width: $large-image-size;
      max-height: $large-image-size;
    }
  }
  }

  .list-item {
    grid-template-columns: auto 1fr 0px;
  }
}

// large nav
nav.small:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
nav.large, nav.small:hover {
  width: $large-nav-size;
  transition: $transition-speed;

  .top-section {
    place-items: center;
    grid-template-columns: auto auto;
    img {
      width: $large-image-size;
      max-width: $large-image-size;
      max-height: $large-image-size;
    }
  }

  .list-item {
    grid-template-columns: auto 1fr 0px;
  }
}

// small nav
nav:not(:hover).small {
  width: $small-nav-size;
  transition: $transition-speed;

  .top-section {
    place-items: center;
    grid-template-columns: auto 0px;
    img {
      width: $small-image-size;
      max-width: $small-image-size;
      max-height: $small-image-size;
    } .checkbox {
      transform: scale(0);
    }
  }

  .list-item {
    grid-template-columns: auto 0px 0px;
  }
}



// hamburger button
.hamb-btn {
  position: absolute;
  margin: 12px;
}

.wrapper {
  transition: $transition-speed;
  z-index: 99;
} .wrapper.mobile, .wrapper.hiddent {
    width: 0;
} .wrapper.large {
  width: $large-nav-size;
} .wrapper.small {
  width: $small-nav-size;
}
</style>