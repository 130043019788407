import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

import Event from "@/classes/Event"
import File from "@/classes/File"
import Notification from "@/classes/Notification"
import Form from "@/classes/Form"
import Contact from "@/classes/Contact"
import Mail from "@/classes/Mail"
import Phone from "@/classes/Phone"
import Note from "@/classes/Note"
import Profile from "@/classes/Profile"

let db = getFirestore()
let collectionName = "rooms"

class Room{
    constructor(id, name, licence, code = null, userAmount = 0, lockLevel = {joining: false, editing: false, access: false}){
        this.id = id
        this.name = name
        this.licence = licence
        this.code = code
        this.userAmount = userAmount
        this.lockLevel = lockLevel
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let rooms = []

        response.forEach(document => {
            let data = document.data()
            let tmp_room = new Room(document.id, data.name, data.licence, data.code, data.userAmount, data.lockLevel)
            rooms.push(tmp_room)
        })

        return rooms
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        let room = !data ? null : new Room(response.id, data.name, data.licence, data.code, data.userAmount, data.lockLevel)

        return room
    }

    static async getByCode(code){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("code", "==", parseInt(code)))
        let response = await getDocs(documentsQuery)

        let first = true
        let tmp_room = null

        response.forEach(room => {
            if(first){
                let data = room.data()
                tmp_room = new Room(room.id, data.name, data.licence, data.code, data.userAmount, data.lockLevel)
                first = false
            }
        })
        return tmp_room
    }

    static async getByLicence(licenceId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("licence", "==", licenceId))
        let response = await getDocs(documentsQuery)

        let rooms = []

        response.forEach(document => {
            let data = document.data()
            let tmp_room = new Room(document.id, data.name, data.licence, data.code, data.userAmount, data.lockLevel)
            rooms.push(tmp_room)
        })
        return rooms
    }

    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let rooms = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_room = new Room(document.id, data.name, data.licence, data.code, data.userAmount, data.lockLevel)
                rooms.push(tmp_room)
            })
            
            if(callback != null){
                callback(rooms)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let room = !data ? null : new Room(snapshot.id, data.name, data.licence, data.code, data.userAmount, data.lockLevel)
            
            if(callback != null){
                callback(room)
            }
        })
        return unsub
    }

    static listenByLicence(licenceId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("licence", "==", licenceId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let rooms = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_room = new Room(document.id, data.name, data.licence, data.code, data.userAmount, data.lockLevel)
                rooms.push(tmp_room)
            })
            
            if(callback != null){
                callback(rooms)
            }
        })
        return unsub
    }

    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }

    async generateNewCode(){
        this.code = parseInt(Math.floor(Math.random() * 1000000))

        let documentRef = doc(db, collectionName, this.id)
        await updateDoc(documentRef, {
            code: this.code,
        })
    }

    async toggleLockLevel(lockLevelType){
        if(new Array("joining", "editing", "access").includes(lockLevelType)){
            this.lockLevel[lockLevelType] = !this.lockLevel[lockLevelType]
        }

        let documentRef = doc(db, collectionName, this.id)
        await updateDoc(documentRef, {
            lockLevel: this.lockLevel,
        })
    }

    async save(){
        if(this.id == null){
            let collectionRef = collection(db, collectionName)
            if(this.code == null){
                this.code = parseInt(Math.floor(Math.random() * 1000000))
            }
            let response = await addDoc(collectionRef, {
                name: this.name,
                licence: this.licence,
                code: this.code,
                userAmount: this.userAmount,
                lockLevel: this.lockLevel
            })
            this.id = response.id
        }else{
            let documentRef = doc(db, collectionName, this.id)
            await updateDoc(documentRef, {
                name: this.name,
                licence: this.licence,
                code: this.code,
                userAmount: this.userAmount,
                lockLevel: this.lockLevel
            })
        }
    }

    async delete(){
        let events = await Event.getByRoom(this.id)
        for(let event of events){
            event.delete()
        }

        let files = await File.getByRoom(this.id)
        for(let file of files){
            file.delete()
        }

        let notifications = await Notification.getByRoom(this.id)
        for(let notification of notifications){
            notification.delete()
        }

        let forms = await Form.getByRoom(this.id)
        for(let form of forms){
            form.delete()
        }

        let contacts = await Contact.getByRoom(this.id)
        for(let contact of contacts){
            contact.delete()
        }

        let mails = await Mail.getByRoom(this.id)
        for(let mail of mails){
            mail.delete()
        }

        let phones = await Phone.getByRoom(this.id)
        for(let phone of phones){
            phone.delete()
        }

        let notes = await Note.getByRoom(this.id)
        for(let note of notes){
            note.delete()
        }

        let profiles = await Profile.getByRoom(this.id)
        for(let profile of profiles){
            if(profile.rooms.includes(this.id)){
                profile.rooms.splice(profile.rooms.indexOf(this.id), 1)
                if(profile.rooms.length == 0){
                    profile.role("None")
                    profile.currentRoom = null
                    profile.licence = null
                }else{
                    profile.currentRoom = profile.rooms[0]
                }
            }
        }

        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Room