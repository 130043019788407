import { computed } from 'vue'

// TODO: themes (replace tmp_isDark with the current vuetify theme)
let tmp_isDark = false

export default {
	app: {
		name: 'Valy',
		logo: require('@/assets/images/svg/logo.svg'),
		darkLogo: require('@/assets/images/svg/logo-dark.svg'),
		isDark: computed({
			get: () => tmp_isDark,
			set: value => {
				tmp_isDark = value
				localStorage.setItem('valy-active-theme', value ? 'dark' : 'light')
			}
		})
	},
	darkTheme: false,
	themes: {
		light: {
			text: '#736c7c', // rgba(94, 86, 105, 0.87)'
			textContrast: '#DDDDDD', // 'rgba(231, 227, 252, 0.87)'
			background: '#FFFFFF',
			primary: '#293A60',
			secondary: '#626273',
			light: '#4d618e',
			lighter: '#9CA6A7',
			darker: '#f6f7fe',
			accent: '#0d6efd',
			success: '#257659',
			info: '#4CC3D0',
			warning: '#FFB400',
			error: '#E45D2B',
			customWhite: '#EEEEFE',
			customBlack: '#333333',
			lightBg: '#f5f8ff',
			darkBg: '#c9d9eb',
			scrollBg: '#f4f6fb',
			scrollthumb: '#626273',
			notif: '#9b59b6',
			//swal
			swalLight: '#FFFFFF',
			swalDark: '#6E6778',
			swalPrimary: '#293A60',
			swalSecondary: '#626273',
			swalSuccess: '#257659',
			swalInfo: '#4CC3D0',
			swalWarning: '#FFB400',
			swalError: '#E45D2B',
			//avatar
			avatarColorDefault: '#9CA6A7',
			avatarColorVariant1: '#c9c7e2',
			avatarColorVariant2: '#e2c7d8',
			avatarColorVariant3: '#b1edbf',
			avatarColorVariant4: '#edd2b1',
			avatarColorVariant5: '#edb1b3',
			// QR
			qrBg: '#FFFFFF',

			debug: '#ff00bb'
		},
		dark: {
			text: '#EEEEEE', // 'rgba(231, 227, 252, 0.87)',
			textContrast: '#736c7c', // 'rgba(94, 86, 105, 0.87)',
			background: '#1C2E40',
			primary: '#3498db',
			secondary: '#AAAAAA',
			light: '#7cb2d6',
			lighter: '#34495e',
			darker: '#1d2e3e',
			accent: '#0d6efd',
			success: '#257659',
			info: '#4CC3D0',
			warning: '#FFB400',
			error: '#E45D2B',
			customWhite: '#EEEEFE',
			customBlack: '#333333',
			lightBg: '#2e4256',
			darkBg: '#1a2c3e',
			scrollBg: '#2e4256',
			scrollthumb: '#4d618e',
			notif: '#e056fd',
			//swal
			swalLight: '#1C2E40',
			swalDark: '#E3E3E3',
			swalPrimary: '#3498db',
			swalSecondary: '#EEEEEE',
			swalSuccess: '#257659',
			swalInfo: '#4CC3D0',
			swalWarning: '#FFB400',
			swalError: '#E45D2B',
			//avatar
			avatarColorDefault: '#34495e',
			avatarColorVariant1: '#c9c7e2',
			avatarColorVariant2: '#e2c7d8',
			avatarColorVariant3: '#b1edbf',
			avatarColorVariant4: '#edd2b1',
			avatarColorVariant5: '#edb1b3',
			// QR
			qrBg: '#27394E',

			debug: '#f33dad'
		}
	}
}
