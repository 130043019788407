<template>
    <div v-if="group">
        <v-avatar size="40px" class="mx-auto v-avatar-avatarColorDefault-bg primary--text" >
            <v-icon>mdi-account-group-outline</v-icon>
        </v-avatar>
    </div>
    <div v-else-if="!profile.uid">
        <v-avatar size="40px" class="mx-auto black--text" v-text="profile.lastname[0].toUpperCase() + profile.firstname[0].toUpperCase()" :color="this.computeColor(profile.lastname+profile.firstname)"></v-avatar>
    </div>
    <div v-else>
        <v-badge avatar overlap color="error" icon="mdi-xml" v-if="profile.dev">
            <v-avatar size="40px" class="primary--text pointer" :color="`hsl(${profile.color}deg,50%,50%)`">
                <v-img :src="require('@/assets/images/avatars/' + profile.avatar)"></v-img>
            </v-avatar>
        </v-badge>

        <v-badge avatar overlap color="warning" icon="mdi-crown-outline" v-else-if="profile.role == 'SuperAdmin'">
            <v-avatar size="40px" class="primary--text pointer" :color="`hsl(${profile.color}deg,50%,50%)`">
                <v-img :src="require('@/assets/images/avatars/' + profile.avatar)"></v-img>
            </v-avatar>
        </v-badge>

        <v-badge avatar overlap color="success" icon="mdi-shield-check" v-else-if="profile.role == 'Admin'">
            <v-avatar size="40px" class="primary--text pointer" :color="`hsl(${profile.color}deg,50%,50%)`">
                <v-img :src="require('@/assets/images/avatars/' + profile.avatar)"></v-img>
            </v-avatar>
        </v-badge>

        <v-badge avatar overlap color="accent" icon="mdi-shield-check" v-else-if="profile.role == 'Corrector' || profile.role == 'Animator'">
            <v-avatar size="40px" class="primary--text pointer" :color="`hsl(${profile.color}deg,50%,50%)`">
                <v-img :src="require('@/assets/images/avatars/' + profile.avatar)"></v-img>
            </v-avatar>
        </v-badge>
        
        <v-avatar size="40px" class="primary--text pointer" :color="`hsl(${profile.color}deg,50%,50%)`" v-else>
            <v-img :src="require('@/assets/images/avatars/' + profile.avatar)"></v-img>
        </v-avatar>
    </div>
</template>

<script>

export default {
    props: ['profile', 'group'],
    methods: {
        computeColor(inputString){
            let saltedString = 'TheCakeIsALie'+inputString+"DoABarrelRoll"
            let encodedString = saltedString.split('').map(char => char.charCodeAt(0)).join('')
            let sum = encodedString.split('').reduce((prev, next) => parseInt(prev) + parseInt(next))
            let number = parseInt(sum.toString()[sum.toString().length - 1])

            let colorName = "avatarColorVariant"+ (((number + encodedString.length) % 5) + 1)

            return colorName
        }
    }
}
</script>