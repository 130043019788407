import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "profiles"

import Notification from "@/classes/Notification"
import MailResponse from "@/classes/MailResponse"
import PhoneResponse from "@/classes/PhoneResponse"
import Note from "@/classes/Note"
import Event from "@/classes/Event"
import Log from "@/classes/Log"

const profileFromData = (id, data) => {
    return !data ? null : new Profile(id, data.uid, data.email, data.firstname, data.lastname, data.avatar, data.role, data.licence, data.licences, data.rooms, data.currentRoom, data.theme, data.fontSize, data.notification, data.notificationVolume, data.notifAreMuted, data.dev, data.envIsProd, data.commercial, data.color, data.beta)
}

class Profile{
    constructor(id, uid, email, firstname = 'default_first_name', lastname = 'default_last_name', avatar = '0.png', role = 'None', licence = null, licences = [], rooms = [], currentRoom = null, theme = "light", fontSize = 100, notification = 1, notificationVolume = 100, notifAreMuted = false, dev = false, envIsProd = true, commercial = false, color = 185, beta = false){
        this.id = id
        this.uid = uid
        this.email = email
        this.firstname = firstname
        this.lastname = lastname
        this.avatar = avatar
        this.role = role
        this.licence = licence
        this.licences = licences
        this.rooms = rooms
        this.currentRoom = currentRoom
        this.theme = theme
        this.fontSize = fontSize
        this.notification = notification
        this.notificationVolume = notificationVolume
        this.notifAreMuted = notifAreMuted
        this.dev = dev
        this.envIsProd = envIsProd
        this.commercial = commercial
        this.color = typeof color !== 'number' ? 185 : color
        this.beta = beta
    }

    // get
    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let profiles = []

        response.forEach(document => {
            let data = document.data()
            let tmp_profile = profileFromData(document.id, data)
            profiles.push(tmp_profile)
        })

        return profiles
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        if (data) {
            let profile = profileFromData(response.id, data)
            return profile
        } else return null
    } 

    static async getByUid(uid){
        const collectionRef = collection(db, collectionName)
		const documentQuery = query(collectionRef, where('uid', '==', uid))
        const response = await getDocs(documentQuery)

        let profile = null
        let first = true

        response.forEach(document => {
            if (first) {
                profile = profileFromData(document.id, document.data())
                first = false
            } else {
                const documentRef = doc(db, collectionName, document.id)
                deleteDoc(documentRef)
            }
        })

        return profile
    }
    static async getByMail(email){
        let collectionRef = collection(db, collectionName)
		let documentQuery = query(collectionRef, where('email', '==', email))
        let response = await getDocs(documentQuery)

        let profile = null
        let first = true

        response.forEach(document => {
            if(first){
                let data = document.data()
                profile = profileFromData(document.id, data)
                first = false
            }else{
                let documentRef = doc(db, collectionName, document.id)
                deleteDoc(documentRef)
            }
        })

        return profile
    } 

    static async getByRoom(roomId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("rooms", "array-contains", roomId))
        let response = await getDocs(documentsQuery)

        let profiles = []

        response.forEach(document => {
            let data = document.data()
            let tmp_profile = profileFromData(document.id, data)
            profiles.push(tmp_profile)
        })
        return profiles
    } 

    static async getByRole(role){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef,where("role", "==", role))
        let response = await getDocs(documentsQuery)

        let profiles = []

        response.forEach(document => {
            let data = document.data()
            let tmp_profile = profileFromData(document.id, data)
            profiles.push(tmp_profile)
        })
        return profiles
    }

    static async getByRoomAndByRole(roomId, role){
        let collectionRef = collection(db, collectionName)
        let documentsQuery
        if(role == 'SuperAdmin') documentsQuery = query(collectionRef, where("role", "==", role))
        else documentsQuery = query(collectionRef, where("rooms", "array-contains", roomId), where("role", "==", role))
        let response = await getDocs(documentsQuery)

        let profiles = []

        response.forEach(document => {
            let data = document.data()
            let tmp_profile = profileFromData(document.id, data)
            profiles.push(tmp_profile)
        })
        return profiles
    }

    static async getByLicenceAndByRole(licenceId, role){
        let collectionRef = collection(db, collectionName)
        let documentsQuery
        if(role == 'SuperAdmin') documentsQuery = query(collectionRef, where('role', '==', role))
        else documentsQuery = query(collectionRef, where('licence', '==', licenceId), where('role', '==', role))
        let response = await getDocs(documentsQuery)

        let profiles = []

        response.forEach(document => {
            let data = document.data()
            let tmp_profile = profileFromData(document.id, data)
            profiles.push(tmp_profile)
        })
        return profiles
    }

    // listen
    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let profiles = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_profile = profileFromData(document.id, data)
                profiles.push(tmp_profile)
            })
            
            if(callback != null){
                callback(profiles)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let profile = profileFromData(snapshot.id, data)
            
            if(callback != null){
                callback(profile)
            }
        })
        return unsub
    }

    static listenByUid(uid, callback = null){
        let collectionRef = collection(db, collectionName)
		let documentsQuery = query(collectionRef, where("uid", "==", uid))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let profile = null
            let first = true

            snapshot.forEach(document => {
                if(first){
                    let data = document.data()
                    profile = profileFromData(document.id, data)
                    first = false
                }else{
                    let documentRef = doc(db, collectionName, document.id)
                    deleteDoc(documentRef)
                }
            })
            
            if(callback != null){
                callback(profile)
            }
        })
        return unsub
    }

    static listenByRoom(roomId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("rooms", "array-contains", roomId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let profiles = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_profile = profileFromData(document.id, data)
                profiles.push(tmp_profile)
            })
            
            if(callback != null){
                callback(profiles)
            }
        })
        return unsub
    }

    static listenByRole(role, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef,where("role", "==", role))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let profiles = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_profile = profileFromData(document.id, data)
                profiles.push(tmp_profile)
            })
            
            if(callback != null){
                callback(profiles)
            }
        })
        return unsub
    }

    static listenByRoomAndByRole(roomId, role, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery
        if(role == 'SuperAdmin') documentsQuery = query(collectionRef, where('role', '==', role))
        else documentsQuery = query(collectionRef, where('rooms', 'array-contains', roomId), where('role', '==', role))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let profiles = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_profile = profileFromData(document.id, data)
                profiles.push(tmp_profile)
            })
            
            if(callback != null){
                callback(profiles)
            }
        })
        return unsub
    }

    static listenByLicenceAndByRole(licenceId, role, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery
        if(role == 'SuperAdmin') documentsQuery = query(collectionRef, where('role', '==', role))
        else documentsQuery = query(collectionRef, where('licence', '==', licenceId), where('role', '==', role))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let profiles = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_profile = profileFromData(document.id, data)
                profiles.push(tmp_profile)
            })
            
            if(callback != null){
                callback(profiles)
            }
        })
        return unsub
    }

    static async updateOnlyThemeById(id, newTheme){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, {
            theme : newTheme
        })
    }

    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                uid: this.uid,
                email: this.email,
                firstname: this.firstname,
                lastname: this.lastname,
                avatar: this.avatar,
                role: this.role,
                licence: this.licence,
                licences: this.licences,
                rooms: this.rooms,
                currentRoom: this.currentRoom,
                theme: this.theme,
                fontSize: this.fontSize,
                notification: this.notification,
                notificationVolume: this.notificationVolume,
                notifAreMuted: this.notifAreMuted,
                dev: this.dev,
                envIsProd: this.envIsProd,
                commercial: this.commercial,
                color: this.color,
                beta: this.beta
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                firstname: this.firstname,
                lastname: this.lastname,
                avatar: this.avatar,
                role: this.role,
                licence: this.licence,
                licences: this.licences,
                rooms: this.rooms,
                currentRoom: this.currentRoom,
                theme: this.theme,
                fontSize: this.fontSize,
                notification: this.notification,
                notificationVolume: this.notificationVolume,
                notifAreMuted: this.notifAreMuted,
                dev: this.dev,
                envIsProd: this.envIsProd,
                commercial: this.commercial,
                color: this.color,
                beta: this.beta
            })
        }
    }

    async delete(){
        if(!this.dev){
            let Notification = await Notification.getByUser(this.id)
            for(let notification of notifications){
                await notification.delete()
            }

            let MailResponse = await MailResponse.getBySender(this.id)
            for(let mailResponse of mailResponses){
                mailResponse.delete()
            }

            let PhoneResponse = await PhoneResponse.getBySender(this.id)
            for(let phoneResponse of phoneResponses){
                phoneResponse.delete()
            }

            let Log = await Log.getByProfileId(this.id)
            for(let log of logs){
                log.delete()
            }

            let Event = await Event.getByUser(this.id)
            for(let event of events){
                event.delete()
            }
            

            for(let room of this.rooms){
                let Note = await Note.getByRoom(room)
                for(let note of notes){
                    delete note.notes[this.id]
                }
                note.save()
            }

            let documentRef = doc(db, collectionName, this.id)
            deleteDoc(documentRef)
        }
    }
}

export default Profile