import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

const db = getFirestore()
const collectionPrefix = envManager.getPrefix()
const collectionId = "signatures"
const collectionName = collectionPrefix + collectionId

class Signature{
    constructor(id, date, endDate, room, isOpen, tolerance = (5*60*1000), signatures, validation = null){
        this.id = id
        this.room = room
        this.date = date
        this.endDate = endDate
        this.isOpen = isOpen
        this.tolerance = tolerance
        this.signatures = signatures
        this.validation = validation
    }

    static async getAll(){
        const collectionRef = collection(db, collectionName)
        const response = await getDocs(collectionRef)

        const signatures = []

        response.forEach(document => {
            const data = document.data()
            const tmp_signature = new Signature(document.id, data.date, data.endDate, data.room, data.isOpen, data.tolerance, data.signatures, data.validation)
        })

        return signatures
    }

    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        const data = response.data()
        const signature = !data ? null : new Signature(response.id, data.date, data.endDate, data.room, data.isOpen, data.tolerance, data.signatures, data.validation)

        return signature
    }

    static async getByRoom(roomId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
        let response = await getDocs(documentsQuery)

        let signatures = []

        response.forEach(document => {
            const data = document.data()
            const tmp_signature = new Signature(document.id, data.date, data.endDate, data.room, data.isOpen, data.tolerance, data.signatures, data.validation)
            signatures.push(tmp_signature)
        })
        return signatures
    }

    static listenAll(callback = null){
        const collectionRef = collection(db, collectionName)
        const unsub = onSnapshot(collectionRef, snapshot => {
            const signatures = []

            snapshot.forEach(document => {
                const data = document.data()
                const tmp_signature = new Signature(document.id, data.date, data.endDate, data.room, data.isOpen, data.tolerance, data.signatures, data.validation)
                signatures.push(tmp_signature)
            })

            if(callback != null){
                callback(signatures)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            const data = snapshot.data()
            const signature = new Signature(snapshot.id, data.date, data.endDate, data.room, data.isOpen, data.tolerance, data.signatures, data.validation)
            
            if(callback != null){
                callback(signature)
            }
        })
        return unsub
    }

    static listenByRoom(roomId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let signatures = []

            snapshot.forEach(document => {
                const data = document.data()
                const tmp_signature = new Signature(document.id, data.date, data.endDate, data.room, data.isOpen, data.tolerance, data.signatures, data.validation)
                signatures.push(tmp_signature)
            })
            
            if(callback != null){
                callback(signatures)
            }
        })
        return unsub
    }
    static listenByRoomAndByIsOpen(roomId, isOpen, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("isOpen", "==", isOpen))
        
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let signatures = []

            snapshot.forEach(document => {
                const data = document.data()
                const tmp_signature = new Signature(document.id, data.date, data.endDate, data.room, data.isOpen, data.tolerance, data.signatures, data.validation)
                signatures.push(tmp_signature)
            })
            
            if(callback != null){
                callback(signatures)
            }
        })
        return unsub
    }

    async save(){
        const collectionRef = collection(db, collectionName)

        if(this.id == null){
            const response = await addDoc(collectionRef, {
                id: this.id,
                room : this.room,
                date: this.date,
                endDate: this.endDate,
                isOpen: this.isOpen,
                tolerance: this.tolerance,
                signatures: this.signatures,
                validation: this.validation
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                id: this.id,
                room : this.room,
                date: this.date,
                endDate: this.endDate,
                isOpen: this.isOpen,
                tolerance: this.tolerance,
                signatures: this.signatures,
                validation: this.validation
            })
        }
    }
    delete(){
        const documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Signature