import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionName = "config"

class Config{
    constructor(shutdown, shutdownTitle, shutdownMessage){
        this.shutdown = shutdown
        this.shutdownTitle = shutdownTitle
        this.shutdownMessage = shutdownMessage
    }
    
    static async get(){
        let instanceRef = doc(db, collectionName, "instance")
        let response = await getDoc(instanceRef)

        let data = response.data()
        let config = new Config(data.shutdown, data.shutdownTitle, data.shutdownMessage)
        
        return config
    }

    static listen(callback = null){
        let instanceRef = doc(db, collectionName, "instance")
        let unsub = onSnapshot(instanceRef, snapshot => {
            let data = snapshot.data()
            let config = new Config(data.shutdown, data.shutdownTitle, data.shutdownMessage)
            
            if(callback != null){
                callback(config)
            }
        })
        return unsub
    }

    async save(){
        let instanceRef = doc(db, collectionName, "instance")

        await setDoc(instanceRef, {
            shutdown: this.shutdown,
            shutdownTitle: this.shutdownTitle,
            shutdownMessage: this.shutdownMessage,
        })

        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)
        response.forEach(document => {
            if(document.id != "instance"){
                let documentRef = doc(db, collectionName, document.id)
                deleteDoc(documentRef)
            }
        })
    }
}

export default Config