import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

let db = getFirestore()
let collectionPrefix = envManager.getPrefix()
let collectionId = "tasks"
let collectionName = collectionPrefix + collectionId

class Task{
    constructor(id, room, type, date, name, desc, checked, user = null){
        this.id = id
        this.room = room
        this.type = type
        this.date = date
        this.name = name
        this.desc = desc
        this.checked = checked
        this.user = user
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let tasks = []

        response.forEach(document => {
            let data = document.data()
            let tmp_task = new Task(document.id, data.room, data.type, data.date, data.name, data.desc, data.checked, data.user)
            tasks.push(tmp_task)
        })

        return tasks
    }
    
    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()

        return (!data) ? null : new Task(document.id, data.room, data.type, data.date, data.name, data.desc, data.checked, data.user)
    }
    
    static async getByRoomAndByType(roomId, type){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("type", "==", type))
        let response = await getDocs(documentsQuery)

        let tasks = []

        response.forEach(document => {
            let data = document.data()
            let tmp_task = new Task(document.id, data.room, data.type, data.date, data.name, data.desc, data.checked, data.user)
            tasks.push(tmp_task)
        })
        return tasks
    } 

    static async getByRoomByTypeAndByUser(roomId, type, userId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("type", "==", type), where("user", "==", userId))
        let response = await getDocs(documentsQuery)

        let tasks = []

        response.forEach(document => {
            let data = document.data()
            let tmp_task = new Task(document.id, data.room, data.type, data.date, data.name, data.desc, data.checked, data.user)
            tasks.push(tmp_task)
        })
        return tasks
    } 

    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let tasks = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_task = new Task(document.id, data.room, data.type, data.date, data.name, data.desc, data.checked, data.user)
                tasks.push(tmp_task)
            })
            
            if(callback != null){
                callback(tasks)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let task = new Task(snapshot.id, data.room, data.type, data.date, data.name, data.desc, data.checked, data.user)
            
            if(callback != null){
                callback(task)
            }
        })
        return unsub
    }

    static listenByRoomAndByType(roomId, type, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("type", "==", type))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let tasks = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_task = new Task(document.id, data.room, data.type, data.date, data.name, data.desc, data.checked, data.user)
                tasks.push(tmp_task)
            })
            
            if(callback != null){
                callback(tasks)
            }
        })
        return unsub
    }

    static listenByRoomByTypeAndByUser(roomId, type, userId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId), where("type", "==", type), where("user", "==", userId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let tasks = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_task = new Task(document.id, data.room, data.type, data.date, data.name, data.desc, data.checked, data.user)
                tasks.push(tmp_task)
            })
            
            if(callback != null){
                callback(tasks)
            }
        })
        return unsub
    }
    
    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                room: this.room,
                type: this.type,
                date: this.date,
                name: this.name,
                desc: this.desc,
                checked: this.checked,
                user: this.user
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                room: this.room,
                type: this.type,
                date: this.date,
                name: this.name,
                desc: this.desc,
                checked: this.checked,
                user: this.user
            })
        }
    }
    
    delete(){
        let documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }

    async saveCheck(){
        await updateDoc(doc(db, collectionName, this.id), {
            checked: this.checked
        })
    }
}

export default Task