<template>
    <v-app v-if="isLoading">
        <div class="loader-container">
            <Loader :xxl="true" />
        </div>
    </v-app>

    <v-app v-else-if="config">
        <ErrorShutdown v-if="config.shutdown && (!profile || !profile.dev)" :config="config" />

        <div v-if="!$route.meta.needAccount">
            <router-view :config="config" />
        </div>

        <div v-else-if="profile" class="custom-row">
            <Navigation :profile="profile" :currentRoom="currentRoom" :notifications="notifications" />
            <div class="custom-column">

                <v-main>
                    <v-app-bar flat class="transparent">
                        <v-spacer />
                        <AppBarRoomChange :profile="profile" v-if="profile.role === 'SuperAdmin'" />
                        <AppBarUserMenu :profile="profile" :currentRoom="currentRoom" />
                    </v-app-bar>

                    <div v-if="roomIsLoading" class="loader-container">
                        <Loader :xxl="true" />
                    </div>
                    <v-container v-else-if="$route.meta.needRoom && (profile.role === 'User') && (!currentRoom || !currentRoom.lockLevel.access)">
                        <v-card class="mt-10 pa-5">
                            <h1 class="text-center">Cette Room est actuellement fermé</h1>
                            <h3 class="text-center">Contacter votre administrateur ou changé de room dans votre profil</h3>
                        </v-card>
                    </v-container>

                    <v-container v-else-if="!$route.meta.authLevel || $route.meta.authLevel[profile.role] || ($route.meta.authLevel.dev && profile.dev)">
                        <router-view :user="user" :profile="profile" :notifications="notifications" :config="config" :currentRoom="currentRoom" />
                    </v-container>

                    <div v-else>
                        <Error401 />
                    </div>

                    <AlertViewer v-for="alert in alerts" :key="alert.id" v-if="!alert.hide.includes(profile.id)" :profile="profile" :alert="alert" />
                    <SignatureAlert v-if="profile && profile.role == 'User'" :profile="profile" :user="user" />
                    <AlertViewer v-if="SignatureNeedToBeCloseAlert" :profile="profile" :SignatureNeedToBeCloseAlert="true" />
                </v-main>

            </div>
        </div>

        <v-spacer />
        <Footer />
	</v-app>
</template>

<script>
import logger from '@/assets/functions/logger'
import notifier from '@/assets/functions/notifier'

import { getAuth } from 'firebase/auth'

// Models
import Config from '@/classes/Config'
import Profile from '@/classes/Profile'
import Notification from '@/classes/Notification'

import Mail from '@/classes/Mail'
import Phone from '@/classes/Phone'
import Event from '@/classes/Event'
import Task from '@/classes/Task'
import File from '@/classes/File'
import Room from '@/classes/Room'
import Alert from '@/classes/Alert'
import Signature from '@/classes/Signature'


// Data
import themeConfig from '@themeConfig'
import envManager from '@/assets/functions/envManager'
import utilities from '@/assets/functions/utilities'

// Components
import ErrorShutdown from '@/views/errors/ErrorShutdown.vue'
import AlertViewer from '@/components/Alert/alertViewer.vue'
import SignatureAlert from '@/components/Signatures/SignatureAlert.vue'

import Navigation from '@/components/Layout/Navigation'
import AppBarRoomChange from '@/components/Layout/AppBarRoomChange'
import AppBarUserMenu from '@/components/Layout/AppBarUserMenu'
import Footer from '@/components/Layout/Footer'
import Loader from '@/components/Loader'
import Error401 from '@/views/errors/Error401'

export default {
    name: 'App',
    components: {
        ErrorShutdown,
        AlertViewer,
        SignatureAlert,
        Footer,
        Navigation,
        AppBarRoomChange,
        AppBarUserMenu,
        Loader,
        Error401
    },
    data() {
        return {
            isDark: themeConfig.app.isDark,
            user: null,
            profile: null,
            notifications: {},
            config: null,
            alerts: [],
            unsub: [],
            currentRoom: null,
            firstUserAction: false, // need 1 user action before start sound
            lastNotif: new Date().getTime(),
            nbNotifications: null,
            SignatureNeedToBeCloseAlert: false,
            isLoading: false,
            roomIsLoading: false
        }
    },
    watch: {
        notifications(newVal, oldVal) {
            if (!this.profile.notifAreMuted) {
                if (this.firstUserAction) {
                    const currentDate = new Date().getTime()

                    let nbOldNotif = 0
                    for (const key in oldVal)
                        nbOldNotif += oldVal[key].length

                    if (currentDate - this.lastNotif > 500) { // prevent spam of notification sound
                        if (this.nbNotifications > nbOldNotif) { // can't use newVal
                            notifier.sound(this.profile.notification, this.profile.notificationVolume)
                            this.lastNotif = currentDate
                        }
                    }
                }
            }
        }
    },
    created() {
        document.addEventListener('click', () => {
            this.firstUserAction = true
        }, {once: true})

        envManager.setProdEnv()

        this.isLoading = true
        this.roomIsLoading = true
        
        this.unsub.push(Config.listen(config => {
            this.config = config
        }))

        getAuth().onAuthStateChanged(firebaseUser => {
            if (firebaseUser) {
                this.user = firebaseUser
                this.unsub.push(Profile.listenByUid(this.user.uid, async profile => {
                    this.isLoading = false

                    if (profile) {
                        let profileHasChange = false
                        this.profile = profile

                        if (this.profile.rooms && this.profile.rooms.length && this.profile.rooms.length > 0 && !this.profile.rooms.includes(this.profile.currentRoom)) {
                            this.profile.currentRoom = this.profile.rooms[0]
                            profileHasChange = true
                        } else if (this.profile.rooms && this.profile.rooms.length && this.profile.rooms.length <= 0 && this.profile.currentRoom != null) {
                            this.profile.currentRoom = null
                            profileHasChange = true
                        }

                        if(this.profile.currentRoom == null){
                            this.roomIsLoading = false
                        }

                        if (!['SuperAdmin', 'Admin', 'None'].includes(this.profile.role)) {
                            let validRooms = []
                            await Promise.all(this.profile.rooms.map(async room => {
                                const tmp_room = await Room.getById(room)
                                if (tmp_room !== null) validRooms.push(room)
                            }))

                            if (this.profile.rooms.length !== validRooms.length) {
                                profileHasChange = true
                                this.profile.rooms = validRooms
                            }
                        }

                        if (profileHasChange) {
                            this.profile.save()
                        }

                        if (profile.role !== 'None') {
                            /**
                             * NOTIFICATIONS
                             */
                            this.unsub.push(
                                Notification.listenByRoomAndByUser(this.profile.currentRoom, this.profile.id, notifications => {

                                    const curentDate = new Date().getTime()
                                    this.notifications = {}
                                    this.nbNotifications = 0

                                    for (const notif of notifications) {
                                        if (this.notifications[notif.type] === undefined) {
                                            this.notifications[notif.type] = []
                                        }

                                        // check date
                                        if (notif.date < curentDate) {
                                            this.nbNotifications++
                                            this.notifications[notif.type].push(notif)
                                        } else {
                                            setTimeout(() => {
                                                this.nbNotifications++

                                                const tmp_notifs = this.notifications
                                                tmp_notifs[notif.type].push(notif)
                                                this.notifications = Object.assign({}, tmp_notifs)

                                                notifier.sound(this.profile.notification, this.profile.notificationVolume)
                                                this.lastNotif = new Date().getTime()
                                            }, notif.date - curentDate)
                                        }

                                        // delete 
                                        if (notif.content === null) notif.delete()
                                        else switch (notif.type) {
                                            case 'mail':
                                                Mail.getById(notif.content).then(data => {
                                                    if (data === null) notif.delete()
                                                })
                                                break
                                            case 'phone':
                                                Phone.getById(notif.content).then(data => {
                                                    if (data === null) notif.delete()
                                                })
                                                break
                                            case 'agenda':
                                                Event.getById(notif.content).then(data => {
                                                    if (data === null) notif.delete()
                                                })
                                                break
                                            case 'todo':
                                                Task.getById(notif.content).then(data => {
                                                    if (data === null) notif.delete()
                                                })
                                                break
                                            case 'file':
                                                File.getById(notif.content).then(data => {
                                                    if (data === null) notif.delete()
                                                })
                                                break
                                        }
                                    }
                                })
                            )

                            this.unsub.push(
                                Room.listenById(this.profile.currentRoom,(currentRoom) => {
                                    this.roomIsLoading = false
                                    this.currentRoom = currentRoom
                                })
                            )

                            this.unsub.push(
                                Alert.listenByReceiver(this.profile, async data => {
                                    const tmp_alerts = []
                                    for (const alert of data) {
                                        if (alert.date + (5*60*1000) >= new Date().getTime()) {// < 5 minutes
                                            if (!alert.hide.includes(this.profile.id)) tmp_alerts.push(alert)
                                        } else {
                                            alert.delete()
                                        }
                                    }
                                    this.alerts = tmp_alerts
                                })
                            )

                            /**
                             * Signature
                             */
                            if (['SuperAdmin', 'Admin'].includes(this.profile.role)) {
                                this.unsub.push(Signature.listenByRoomAndByIsOpen(this.profile.currentRoom, true, data => {
                                    const signatures = data.filter(sign => sign.endDate)
                                    const currentDate = new Date().getTime()
                                    this.SignatureNeedToBeCloseAlert = false

                                    for (const signature of signatures) {
                                        setTimeout(() => {
                                            this.SignatureNeedToBeCloseAlert = true
                                        }, signature.endDate - currentDate)
                                    }
                                }))
                            }
                        }


                        if(this.profile.dev && !this.profile.envIsProd){
                            envManager.setDevEnv()
                        }else{
                            envManager.setProdEnv()
                        }

                    } else {
                        this.profile = new Profile(null, this.user.uid, this.user.email)
                        this.profile.save()
                        .then(() => {
                            envManager.setProdEnv()
                            logger.log(this.profile.id, "INSCRIPTION", this.profile.email + " vient de creer son profile")
                            window.location.reload()
                        })
                    }
                    // TODO: themes
                    // this.isDark = profile.theme === 'dark'
                    utilities.updateFontSize(profile.fontSize)
                }))
            } else {
                this.user = null
                this.profile = null
                this.isDark = false
                this.isLoading = false
                this.roomIsLoading = false
                this.$router.push('/login').catch(() => {})
            }
        })
    },
    destroyed() {
        this.unsub.forEach(unsub => {
            if (typeof unsub === 'function') unsub()
        })
    },
}
</script>

<style>
    *::-webkit-scrollbar {
        width: 10px;
        height: 10px;
    }
    *::-webkit-scrollbar-track {
        background-color: var(--v-scrollBg-base);
        border-radius: 10px;
    }
    *::-webkit-scrollbar-thumb {
        background-color: var(--v-scrollthumb-base);
        border-radius: 10px;
    }
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
    body::-webkit-scrollbar-track {
        border-radius: unset;
    }
</style>

<style scoped lang="scss">
.custom-row {
    height: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    background-color: var(--v-scrollBg-base);
}
.custom-column {
    min-height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 8px;
}
.loader-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
