import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, updateDoc, onSnapshot, deleteDoc } from "firebase/firestore"
import Group from "@/classes/Group"

const db = getFirestore()
const collectionName = envManager.getPrefix() + 'alerts'

const listenDataWhere = async (callback, attribute1, symbol1, value1, attribute2, symbol2, value2) => {
    let documentsQuery = collection(db, collectionName)
    
    if (attribute1 && symbol1 && value1 && attribute2 && symbol2 && value2) {
        documentsQuery = query(documentsQuery,
            where(attribute1, symbol1, value1),
            where(attribute2, symbol2, value2)
        )
    } else if (attribute1 && symbol1 && value1) {
        documentsQuery = query(documentsQuery,
            where(attribute1, symbol1, value1)
        )
    }

    let unsub = onSnapshot(documentsQuery, snapshot => {
        const dataList = []

        snapshot.forEach(document => {
            const data = document.data()
            const tmp_data = new Alert(document.id, data.text, data.type, data.receiver, data.room, data.hide, data.date)
            dataList.push(tmp_data)
        })
        
        if (callback != null) {
            callback(dataList)
        }
    })
    return unsub
}

class Alert{
    constructor(id, text, type, receiver, room, hide = [], date = new Date().getTime()){
        this.id = id
        this.text = text
        this.type = type
        this.receiver = receiver
        this.room = room
        this.hide = hide
        this.date = date
    }

    // listen
    static async listenByRoom(id, callback = null) {
        return listenDataWhere(callback, 'room', '==', id)
    }
    static async listenByReceiver(profile, callback = null) {
        let array = ['all', profile.id]
        const groups = await Group.getByRoomAndByOneMember(profile.currentRoom, profile.id)
        array = array.concat(groups.map(gr=>gr.id))
        return listenDataWhere(callback, 'room', '==', profile.currentRoom, 'receiver', 'in', array)
    }

    // edit db
    async save() {
        const collectionRef = collection(db, collectionName)

        if (this.id === null) {
            const response = await addDoc(collectionRef, {
                text: this.text,
                type: this.type,
                receiver: this.receiver,
                room: this.room,
                hide: this.hide,
                date: this.date
            })
            this.id = response.id
        } else {
            await updateDoc(doc(db, collectionName, this.id), {
                text: this.text,
                type: this.type,
                receiver: this.receiver,
                room: this.room,
                hide: this.hide,
                date: this.date
            })
        }
    }
    delete() {
        const documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default Alert