import * as XLSX from "xlsx/xlsx.mjs"
import Swal from 'sweetalert2/dist/sweetalert2.js'

import Log from "@/classes/Log"
import Profile from "@/classes/Profile"

let logger = {
	log(profileId, type, message) {
		let log = new Log(null, profileId, type, message)
        log.save()
	},
    async cleanLog(userId){
        console.log("cleanLog")
        let logs = await Log.getByProfileId(userId)
        for(let log of logs){
            console.log(log)
        }
    },
	convertLogsToExcel(logs, fileName = "logs") {
		let workbook = XLSX.utils.book_new()
		let worksheet = XLSX.utils.json_to_sheet(logs, {header: ["timestamp","profileId", "type", "message"]})
		XLSX.utils.book_append_sheet(workbook, worksheet, "LOGS")
		XLSX.writeFile(workbook, fileName + ".xlsx")
	},

	extractProfileLogs(profileId) {
        Log.getByProfileId(profileId)
        .then(logs => {
            if(logs.length > 0){
                let data = []

                logs.sort((a, b) => a.timestamp > b.timestamp ? -1 : 1)

                for(let log of logs){
                    data.push(log.toReadableData())
                }

				logger.convertLogsToExcel(data)
            }else{
                Swal.fire({
                    title: "Aucun log",
                    text: "Aucun log n'a été trouvé.",
                    icon: "info",
                    confirmButtonText: "Ok",
                })
            }
        })
	},

	async extractRoomLogs(roomId) {
        let userProfiles = await Profile.getByRoom(roomId)
        let rawRoomLogs = []

        for(let profile of userProfiles){
            let logs = await Log.getByProfileId(profile.id)
            if(logs.length > 0){
                rawRoomLogs = rawRoomLogs.concat(logs)
            }
        }

        if(rawRoomLogs.length > 0){
            rawRoomLogs.sort((a, b) => a.timestamp > b.timestamp ? -1 : 1)
            
            let data = []
            for(let log of rawRoomLogs){
                data.push(log.toReadableData())
            }

            logger.convertLogsToExcel(data)
        }else{
            Swal.fire({
                title: "Aucun log",
                text: "Aucun log n'a été trouvé.",
                icon: "info",
                confirmButtonText: "Ok",
            })
        }
	},

	extractAllLogs() {
        Log.getAll()
        .then((logs) => {
            if(logs.length > 0){
                let data = []

                logs.sort((a, b) => a.timestamp > b.timestamp ? -1 : 1)

                for(let log of logs){
                    data.push(log.toReadableData())
                }

				logger.convertLogsToExcel(data)
            }else{
                Swal.fire({
                    title: "Aucun log",
                    text: "Aucun log n'a été trouvé.",
                    icon: "info",
                    confirmButtonText: "Ok",
                })
            }
        })
	},
}

export default logger
