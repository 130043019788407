<template>
  <v-footer class="d-flex justify-space-around scrollBg">
    <i v-text="'Valy (v. ' + version + ') Copyright ©'+new Date().getFullYear()+' LoesusPedagogia'" />
  </v-footer>
</template>

<script>
import packageJSON from '@/../package.json'

export default {
  name: 'Footer',
  data() {
    return {
      version: packageJSON.version
    }
  }
}
</script>