import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

import Mail from "@/classes/Mail"
import Phone from "@/classes/Phone"

let db = getFirestore()
let collectionPrefix = envManager.getPrefix()
let collectionId = "contacts"
let collectionName = collectionPrefix + collectionId

class Contact{
    constructor(id, room, firstname, lastname, domain, description){
        this.id = id
        this.room = room
        this.firstname = firstname
        this.lastname = lastname
        this.domain = domain
        this.description = description
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let contacts = []

        response.forEach(document => {
            let data = document.data()
            let tmp_contact = new Contact(document.id, data.room, data.firstname, data.lastname, data.domain, data.description)
            contacts.push(tmp_contact)
        })

        return contacts
    }
    
    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()
        if (data) {
            let contact = new Contact(response.id, data.room, data.firstname, data.lastname, data.domain, data.description)
            return contact
        } else return null
    }

    static async getByRoom(roomId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
        let response = await getDocs(documentsQuery)

        let contacts = []

        response.forEach(document => {
            let data = document.data()
            let tmp_contact = new Contact(document.id, data.room, data.firstname, data.lastname, data.domain, data.description)
            contacts.push(tmp_contact)
        })
        return contacts
    } 

    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let contacts = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_contact = new Contact(document.id, data.room, data.firstname, data.lastname, data.domain, data.description)
                contacts.push(tmp_contact)
            })
            
            if(callback != null){
                callback(contacts)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let contact = new Contact(snapshot.id, data.room, data.firstname, data.lastname, data.domain, data.description)
            
            if(callback != null){
                callback(contact)
            }
        })
        return unsub
    }

    static listenByRoom(roomId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let contacts = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_contact = new Contact(document.id, data.room, data.firstname, data.lastname, data.domain, data.description)
                contacts.push(tmp_contact)
            })
            
            if(callback != null){
                callback(contacts)
            }
        })
        return unsub
    }
    
    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                room: this.room,
                firstname: this.firstname,
                lastname: this.lastname,
                domain: this.domain,
                description: this.description,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                room: this.room,
                firstname: this.firstname,
                lastname: this.lastname,
                domain: this.domain,
                description: this.description,
            })
        }
    }
    
    delete(){
        Mail.getBySender(this.id)
        .then(responses => {
            responses.forEach(response => {
                response.delete()
            })

            Phone.getBySender(this.id)
            .then(responses => {
                responses.forEach(response => {
                    response.delete()
                })
                
                let documentRef = doc(db, collectionName, this.id)
                deleteDoc(documentRef)
            })
        })
    }
}

export default Contact