import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

import PhoneResponse from "@/classes/PhoneResponse"

let db = getFirestore()
let collectionPrefix = envManager.getPrefix()
let collectionId = "phone"
let collectionName = collectionPrefix + collectionId

class Phone{
    constructor(id, room, date, responseType, sender, subject, file){
        this.id = id
        this.room = room
        this.date = date
        this.responseType = responseType
        this.sender = sender
        this.subject = subject
        this.file = file
    }

    static async getAll(){
        let collectionRef = collection(db, collectionName)
        let response = await getDocs(collectionRef)

        let phones = []

        response.forEach(document => {
            let data = document.data()
            let tmp_phone = new Phone(document.id, data.room, data.date, data.responseType, data.sender, data.subject, data.file)
            phones.push(tmp_phone)
        })

        return phones
    }
    
    static async getById(id){
        let documentRef = doc(db, collectionName, id)
        let response = await getDoc(documentRef)

        let data = response.data()

        return (!data) ? null : new Phone(response.id, data.room, data.date, data.responseType, data.sender, data.subject, data.file)
    }

    static async getByRoom(roomId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
        let response = await getDocs(documentsQuery)

        let phones = []

        response.forEach(document => {
            let data = document.data()
            let tmp_phone = new Phone(document.id, data.room, data.date, data.responseType, data.sender, data.subject, data.file)
            phones.push(tmp_phone)
        })
        return phones
    } 

    static async getBySender(senderId){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("sender", "==", senderId))
        let response = await getDocs(documentsQuery)

        let phones = []

        response.forEach(document => {
            let data = document.data()
            let tmp_phone = new Phone(document.id, data.room, data.date, data.responseType, data.sender, data.subject, data.file)
            phones.push(tmp_phone)
        })
        return phones
    } 

    static listenAll(callback = null){
        let collectionRef = collection(db, collectionName)
        let unsub = onSnapshot(collectionRef, snapshot => {
            let phones = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_phone = new Phone(document.id, data.room, data.date, data.responseType, data.sender, data.subject, data.file)
                phones.push(tmp_phone)
            })
            
            if(callback != null){
                callback(phones)
            }
        })
        return unsub
    }

    static listenById(id, callback = null){
        let documentRef = doc(db, collectionName, id)
        let unsub = onSnapshot(documentRef, snapshot => {
            let data = snapshot.data()
            let phone = new Phone(snapshot.id, data.room, data.date, data.responseType, data.sender, data.subject, data.file)
            
            if(callback != null){
                callback(phone)
            }
        })
        return unsub
    }

    static listenByRoom(roomId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("room", "==", roomId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let phones = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_phone = new Phone(document.id, data.room, data.date, data.responseType, data.sender, data.subject, data.file)
                phones.push(tmp_phone)
            })
            
            if(callback != null){
                callback(phones)
            }
        })
        return unsub
    }

    static listenBySender(senderId, callback = null){
        let collectionRef = collection(db, collectionName)
        let documentsQuery = query(collectionRef, where("sender", "==", senderId))
    
        let unsub = onSnapshot(documentsQuery, snapshot => {
            let phones = []

            snapshot.forEach(document => {
                let data = document.data()
                let tmp_phone = new Phone(document.id, data.room, data.date, data.responseType, data.sender, data.subject, data.file)
                phones.push(tmp_phone)
            })
            
            if(callback != null){
                callback(phones)
            }
        })
        return unsub
    }
    
    static async updateById(id, newData){
        let documentRef = doc(db, collectionName, id)
        await updateDoc(documentRef, newData)
    }

    async save(){
        let collectionRef = collection(db, collectionName)
        if(this.id == null){
            let response = await addDoc(collectionRef, {
                room: this.room,
                date: this.date,
                responseType: this.responseType,
                sender: this.sender,
                subject: this.subject,
                file: this.file,
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                room: this.room,
                date: this.date,
                responseType: this.responseType,
                sender: this.sender,
                subject: this.subject,
                file: this.file,
            })
        }
    }
    
    delete(){
        PhoneResponse.getByPhone(this.id)
        .then(responses => {
            responses.forEach(response => {
                response.delete()
            })

            let documentRef = doc(db, collectionName, this.id)
            deleteDoc(documentRef)
        })
    }

    /**
     * get a ref and return if this ref are used elsewhere
     * @param {string} refs
     * @returns boolean
     */
     static async checkIfFileIsUsed(ref, phoneId) {
        const collectionRef = collection(db, collectionName)
        const documentsQuery = query(collectionRef,
            where('file', '==', ref)
        )
        const response = await getDocs(documentsQuery)

        let isUsed = false

        const phones = response.docs.filter(item => {
            isUsed = item.id != phoneId
            return isUsed
        })

        return isUsed
    }
}

export default Phone