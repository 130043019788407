<template>
  <div class="d-flex align-center">
    {{ message }}
    <div class="lds-roller ml-3" :style="`--size: ${xxl ? 4 : .75};`">
        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['message', 'xxl']
}
</script>

<style scoped>
.lds-roller {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  transform: scale(var(--size));
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 20px 20px;
}
.lds-roller div:after {
  content: '';
  display: block;
  position: absolute;
  width: 3.5px;
  height: 3.5px;
  border-radius: 50%;
  background: var(--v-text-base);
  margin: -2px 0 0 -2px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 31.5px;
  left: 31.5px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 34px;
  left: 28px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 35.5px;
  left: 24px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 36px;
  left: 20px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 35.5px;
  left: 16px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 34px;
  left: 12px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 31.5px;
  left: 8.5px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 28px;
  left: 6px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>