<template>
	<v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content" v-if="profile && profile.avatar" origin="top right" transition="scale-transition">
		<template v-slot:activator="{ on, attrs }">
            <span v-on="on">
                <UserAvatar :profile="profile"></UserAvatar>
            </span>
		</template>

		<v-list>
			<div class="pb-3 pt-2 ml-3 d-flex">
                <UserAvatar :profile="profile"></UserAvatar>

				<div class="d-flex flex-column justify-center ml-3">
					<span class="text--primary font-weight-semibold mb-n1" v-text="profile.lastname"></span>
					<small class="text--primary text-capitalize" v-text="profile.firstname"></small>
				</div>
			</div>
            
			<v-divider></v-divider>
            
			<div class="pb-3 pt-2 text-center" v-if="currentRoom">
                <small class="text--primary text-capitalize" v-text="'Room Actuelle : ' + currentRoom.name"></small>
            </div>

			<v-divider></v-divider>

			<!-- Profil -->
			<v-list-item href="#" @click.prevent="goToRoute('profil')">
                <v-icon size="22" class="mr-3">mdi-account-outline</v-icon>
                <v-list-item-title>Profil</v-list-item-title>
			</v-list-item>

			<!-- Logout -->
			<v-list-item href="#" @click="logout">
                <v-icon size="22" class="mr-3">mdi-logout-variant</v-icon>
                <v-list-item-title>Déconnexion</v-list-item-title>
			</v-list-item>
		</v-list>
	</v-menu>
</template>

<script>
import UserAvatar from "@/components/UserAvatar.vue"

import { getAuth } from "firebase/auth"

export default {
    props: ['profile', 'currentRoom'],
    components: { UserAvatar },
    data() {
        return {
            unsub : [],
        }
    },
    methods: {
        goToRoute(route) {
            if (this.$router.currentRoute.name != route) {
                this.$router.push("/" + route)
            }
        },
        logout() {
            getAuth().signOut()
            window.location.href = "/login"
        },
    },
    destroyed() {
        this.unsub.forEach(unsub => unsub())
    },
}
</script>

<style lang="scss">
	.user-profile-menu-content {
		.v-list-item {
			min-height: 2.5rem !important;
		}
	}
</style>
