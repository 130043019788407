export default [
	{ title: 'Accueil',
		icon: 'mdi-home-outline',
		to: 'home',
		notifType: ['mail', 'directMailResponse', 'phone', 'file']
	},
	{ title: 'Éléments',
		icon: 'mdi-package-variant-closed',
		children: [
			{ title: 'Contacts',
				icon: 'mdi-card-account-mail-outline',
				to: 'contacts'
			},
			{ title: 'Groupes',
				icon: 'mdi-account-group-outline',
				to: 'groups'
			},
			{ title: 'Formulaires',
				icon: 'mdi-chat-question-outline',
				to: 'forms'
			},
			{ title: 'Formulaires v2',
				icon: 'mdi-play-box-outline',
				to: 'slideforms'
			}
		]
	},
	{ title: 'Applications',
		icon: 'mdi-apps',
		children: [
			{ title: 'Email',
				icon: 'mdi-email-outline',
				to: 'email',
				notifType: ['mail', 'directMailResponse'],
				hideWhenEmpty: 'mails'
			},
			{ title: 'Répondeur',
				icon: 'mdi-phone-outline',
				to: 'phone',
				notifType: ['phone'],
				hideWhenEmpty: 'phone'
			},
			{ title: 'Agenda',
				icon: 'mdi-calendar-month',
				to: 'agenda',
				notifType: ['agenda']
			},
			{ title: 'Liste de tâches',
				icon: 'mdi-format-list-checks',
				to: 'todo',
				notifType: ['todo']
			},
			{ title: 'Fichiers',
				icon: 'mdi-folder-outline',
				to: 'files',
				notifType: ['file'],
				hideWhenEmpty: 'files'
			},
		],
	},
	{ title: 'Communication',
		icon: 'mdi-phone-message-outline',
		children: [
			{ title: 'Chat',
				icon: 'mdi-message-outline',
				to: 'message',
				notifType: ['message']
			},
			// { title: 'Visio',
			// 	icon: 'mdi-camera-outline',
			// 	to: 'visio',
			// 	notifType: ['visio']
			// },
			{ title: 'Alertes',
				icon: 'mdi-alert-circle-outline',
				to: 'alertes'
			}
		]
	},
	// { title: 'Notes',
	// 	icon: 'mdi-brain',
	// 	to: 'notes',
	// 	hideWhenEmpty: 'notes'
	// },
	// { title: 'Valy ?',
	// 	icon: 'mdi-lightbulb-question',
	// 	children: [
	// 		{ title: 'Comment ça marche ?',
	// 			icon: 'mdi-lightbulb-on-outline',
	// 			to: 'documentation'
	// 		},
	// 		{ title: 'FAQ',
	// 			icon: 'mdi-forum',
	// 			to: 'faq'
	// 		}
	// 	]
	// },
	{ title: 'Gestion admin',
		icon: 'mdi-cog-outline',
		children: [
			{ title: 'Gerer mes utilisateurs',
				icon: 'mdi-account-group-outline',
				to: 'users'
			},
			{ title: 'Gerer ma licence',
				icon: 'mdi-key-outline',
				to: 'licences'
			},
			{ title: 'Gérer mes rooms',
				icon: 'mdi-google-classroom',
				to: 'rooms'
			},
			{ title: 'Gerer l\'émargement',
				icon: 'mdi-draw',
				to: 'signatures'
			},
			{ title: 'Gerer les logs',
				icon: 'mdi-math-log',
				to: 'logs'
			}
		]
	},
	{ title: 'Dev',
		icon: 'mdi-application-brackets-outline',
		children: [
			{ title: 'DB Tools',
				icon: 'mdi-content-save-outline',
				to: 'dbTools'
			},
			{ title: 'Shutdown',
				icon: 'mdi-power-plug-off-outline',
				to: 'shutdown'
			}
		]
	}
]
