import Vue from 'vue'
import VueRouter from 'vue-router'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import Profile from '@/classes/Profile'

Vue.use(VueRouter)

const routes = [
	// home
	{
		path: '/',
		redirect: '/home',
	},
	{ name: 'home',
		path: '/home',
		component: () => import('@/views/Home.vue'),
		meta: {
			title: 'Accueil',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: true,
				User: true,
				Corrector: true,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	// Elements
	{ name: 'contacts',
		path: '/contacts',
		component: () => import('@/views/Contacts.vue'),
		meta: {
			title: 'Contacts',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: false,
				User: false,
				Corrector: true,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	{ name: 'groups',
		path: '/groups',
		component: () => import('@/views/Groups.vue'),
		meta: {
			title: 'Groupes',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: false,
				User: false,
				Corrector: true,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	{ name: 'forms',
		path: '/forms',
		component: () => import('@/views/Forms.vue'),
		meta: {
			title: 'Formulaires',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: false,
				User: false,
				Corrector: true,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	{ name: 'slideforms',
		path: '/slideforms',
		component: () => import('@/views/SlideForms.vue'),
		meta: {
			title: 'Formulaires',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: false,
				User: false,
				Corrector: true,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	// Applications
	{ name: 'email',
		path: '/email',
		component: () => import('@/views/Email.vue'),
		meta: {
			title: 'Emails',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: false,
				User: true,
				Corrector: true,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	{ name: 'phone',
		path: '/phone',
		component: () => import('@/views/Phone.vue'),
		meta: {
			title: 'Répondeur',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: false,
				User: true,
				Corrector: true,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	{ name: 'agenda',
		path: '/agenda',
		component: () => import('@/views/Agenda.vue'),
		meta: {
			title: 'Agenda',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: false,
				User: true,
				Corrector: true,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	{ name: 'todo',
		path: '/todo',
		component: () => import('@/views/Todo.vue'),
		meta: {
			title: 'Todo',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: false,
				User: true,
				Corrector: true,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	{ name: 'files',
		path: '/files',
		component: () => import('@/views/Files.vue'),
		meta: {
			title: 'Fichiers',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: false,
				User: true,
				Corrector: true,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	// Communication
	{ name: 'message',
		path: '/message',
		component: () => import('@/views/Message.vue'),
		meta: {
			title: 'Messages',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: false,
				User: true,
				Corrector: true,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	{ name: 'visio',
		path: '/visio',
		component: () => import('@/views/Visio.vue'),
		meta: {
			title: 'Visio',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: false,
				User: true,
				Corrector: true,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	{ name: 'alertes',
		path: '/alertes',
		component: () => import('@/views/Alerts.vue'),
		meta: {
			title: 'Alertes',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: false,
				User: false,
				Corrector: false,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	//
	{ name: 'notes',
		path: '/notes',
		component: () => import('@/views/Notes.vue'),
		meta: {
			title: 'Notes',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: false,
				User: true,
				Corrector: true,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	// Valy ?
	{ name: 'documentation',
		path: '/documentation',
		component: () => import('@/views/Documentation.vue'),
		meta: {
			title: 'Documentation',
			needAccount: true,
			needRoom: false,
			authLevel: {
				None: true,
				User: true,
				Corrector: true,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	{ name: 'faq',
		path: '/faq',
		component: () => import('@/views/FAQ.vue'),
		meta: {
			title: 'Questions',
			needAccount: true,
			needRoom: false,
			authLevel: {
				None: true,
				User: true,
				Corrector: true,
				Animator: true,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	// Gestion admin
	{ name: 'users',
		path: '/users',
		component: () => import('@/views/Users.vue'),
		meta: {
			title: 'Utilisateurs',
			needAccount: true,
			needRoom: false,
			authLevel: {
				None: false,
				User: false,
				Corrector: false,
				Animator: false,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	{ name: 'licences',
		path: '/licences',
		component: () => import('@/views/Licences.vue'),
		meta: {
			title: 'Licences',
			needAccount: true,
			needRoom: false,
			authLevel: {
				None: false,
				User: false,
				Corrector: false,
				Animator: false,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	{ name: 'rooms',
		path: '/rooms',
		component: () => import('@/views/Rooms.vue'),
		meta: {
			title: 'Rooms',
			needAccount: true,
			needRoom: false,
			authLevel: {
				None: false,
				User: false,
				Corrector: false,
				Animator: false,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	{ name: 'signatures',
		path: '/signatures',
		component: () => import('@/views/Signatures.vue'),
		meta: {
			title: 'Signatures',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: false,
				User: false,
				Corrector: false,
				Animator: false,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	{ name: 'logs',
		path: '/logs',
		component: () => import('@/views/Logs.vue'),
		meta: {
			title: 'Logs',
			needAccount: true,
			needRoom: true,
			authLevel: {
				None: false,
				User: false,
				Corrector: false,
				Animator: false,
				Admin: true,
				SuperAdmin: true
			}
		}
	},
	// Dev
	{ name: 'dbTools',
		path: '/dbTools',
		component: () => import('@/views/Dev/DbTools.vue'),
		meta: {
			title: 'DB Tools',
			needAccount: true,
			needRoom: false,
			authLevel: {
				None: false,
				User: false,
				Corrector: false,
				Animator: false,
				Admin: false,
				SuperAdmin: false,
				dev: true
			}
		}
	},
	{ name: 'shutdown',
		path: '/shutdown',
		component: () => import('@/views/Dev/Shutdown.vue'),
		meta: {
			title: 'Shutdown',
			needAccount: true,
			needRoom: false,
			authLevel: {
				None: false,
				User: false,
				Corrector: false,
				Animator: false,
				Admin: false,
				SuperAdmin: false,
				dev: true
			}
		}
	},
	//

	{ name: 'profil',
		path: '/profil',
		component: () => import('@/views/Profil.vue'),
		meta: {
			title: 'Profil',
			needAccount: true,
			needRoom: false
		}
	},
	{ name: 'login',
		path: '/login',
		component: () => import('@/views/Login.vue'),
		meta: {
			title: 'Connexion',
			needAccount: false,
			needRoom: false
		}
	},

	{ name: 'signing',
		path: '/signing/:profileId/:signatureId',
		component: () => import('@/views/Signing.vue'),
		meta: {
			title: 'Signature',
			container: false,
			needAccount: false,
			needRoom: false,
		}
	},
	
	{ name: 'beta',
		path: '/beta',
		component: () => import('@/views/Dev/Beta.vue'),
		meta: {
			title: 'Beta',
			needAccount: true,
			needRoom: false
		}
	},

	{ name: 'errorShutdown',
		path: '/errorShutdown',
		component: () => import('@/views/errors/ErrorShutdown.vue'),
		meta: {
			title: 'Erreur',
			needAccount: false,
			needRoom: false
		}
	},
	{ name: 'error401',
		path: '/401',
		component: () => import('@/views/errors/Error401.vue'),
		meta: {
			title: '401',
			needAccount: false,
			needRoom: false
		}
	},
	{ name: 'error404',
		path: '*',
		component: () => import('@/views/errors/Error404.vue'),
		meta: {
			title: '404',
			needAccount: false,
			needRoom: false
		}
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
})

getAuth().onAuthStateChanged(firebaseUser => {
	if (!firebaseUser && router.currentRoute.meta.needAccount) {
		router.push('/login').catch(() => {})
	}
})

router.beforeEach(async (to, from, next) => {
	if (to.name === 'login') {
		getAuth().signOut()
	}

	next()
})

router.afterEach((to, from) => {
	Vue.nextTick(() => {
		document.title = 'Valy' + (to.meta.title ? ' - ' + to.meta.title : ' - ' + to.name)
	})
})

export default router
