let envManager = {
    localAddress : "b2701e711b92f7b3fca28eddf970b8adb15d86a082954303af326c18792b6aae",
    prod : "b996e7eaf8a2b199279360a76b18ab92c4191d84b37e1243c90cd1d7bb966567",
    dev : "73475cb40a568e8da8a045ced110137e159f890ac4da883b6b17dc651b3a8049",
    prodPrefix : "env/prod/",
    devPrefix : "env/dev/",
    
    setProdEnv(){
        window.localStorage.setItem(this.localAddress, this.prod)
    },
    setDevEnv(){
        window.localStorage.setItem(this.localAddress, this.dev)
    },
    getEnvName(){
        return "Prod"
        if(window.location.host.includes('localhost') || window.localStorage.getItem(this.localAddress) == this.dev){
            return "Dev"
        }else{
            return "Prod"
        }
    },
    getPrefix(){
        return this.prodPrefix
        if(window.location.host.includes('localhost') || window.localStorage.getItem(this.localAddress) == this.dev){
            return this.devPrefix
        }else{
            return this.prodPrefix
        }
    },
}

export default envManager